.newsSection {
    padding: rem(20px 15px);

    .newsBlock {
        margin: rem(70px 0);

        &__figure {
            max-width: 280px;
            padding-right: rem(30px);
            cursor: pointer;
            @include media-breakpoint-down(sm) {
                min-width: 100%;
                img{
                    max-width: 100%;
                }
            }
        }

        &__content{
            @include media-breakpoint-down(sm) {
                margin-top: 20px;
            }

            &__detail{
                margin: rem(15px 0);
            }
        }

        &:nth-child(even) {
            .newsBlock__figure{
                @include media-breakpoint-up(md) {
                    order: 2;
                    padding-left: rem(30px);
                    padding-right: 0;
                }
            }
        }

        .link{
            cursor: pointer;
            font-size: rem(16px);
        }
    }
}

.news-slider{
    max-height: 96vh
}

.modal-open {
    overflow: hidden !important;
}

.sevicelistSection{
    .newsBlock {
        margin: rem(80px 0);

        &__figure {
            max-width: 340px;
        }
    }
}