.potfolio-section {
    background: $lightest-grey;
    padding: 40px 0;

    @media only screen and (min-width: $smd) {
        padding: 80px 0;
    }
}

.portfolio-nav-sec {
    .side-nav {
        background: $white;
        max-width: 305px;
        height: calc(100% - 37px)
    }
}

.portfolio-container{
    max-width: rem(1050px);
    margin: 0 auto;
    position: relative;
}

.portfolio-item-list {
    display: flex;
    flex-wrap: wrap;
    margin-right: -11px;
    margin-left: -11px;

    .portfolio-item {
        flex: 0 0 100%;
        max-width: 300px;
        padding: 0 12px;
        margin-bottom: 32px;

        @media only screen and (min-width: 481px) {
            flex: 0 0 50%;
            padding: 0 11px;
            max-width: 50%;
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 33.33%;
             max-width: 33.33%;
        }

        @include media-breakpoint-up(lg) {
            flex: 0 0 25%;
            max-width: 25%;
        }

        .portfolio-thumb {
            box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.16);
            transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
            transition-delay: .1s;
            transform: translateZ(0);

            img {
                max-width: 100%;
                width: 100%;
            }
        }

        h2 {
            margin:rem(15px 0  0);

            a {
                font-size: rem(18px);
                color: $darkest-grey;
                font-weight: 400;
                position: relative;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                
                @media only screen and (min-width: $smd) {
                    font-size: rem(22px);
                }

                &::after {
                    content: attr(data-hover);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    overflow: hidden;
                    color: $blue;
                    transition: width .9s ease-in-out;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .portfolio-meta,
        .portfolio-meta a {
            font-size: rem(15px);
            color: $darker-grey;
        }

        &:hover {
            .portfolio-thumb{
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
                opacity: 1;
                transform: translate(0, 0px);
            }
            a {
                text-decoration: none;
                &::after {
                    width: 100%;
                }
            }
        }
    }
}

.top-portfolio-section {
    padding-bottom: 45px;

    &.gray-bg {
        background: $lightest-grey;
        padding: 50px 0 20px;

        @media only screen and (min-width: $smd) {
            padding: 80px 0 50px;
        }
    }

    .sectionHeading {
        padding-bottom: 40px
    }

    .portfolio-scroll {
        width: 100%;
        overflow-x: auto;

        .portfolio-item-list {
            width: 965px;

            .portfolio-item {
                max-width: 241px;
                flex: 0 0 241px;

                @media only screen and (min-width: $smd) {
                    max-width: 25%;
                    flex: 0 0 25%;
                }
            }
        }
    }
}

.seeMore{
    margin: rem(30px 0 10px);
}
.portfolio-banner {
    .portfolio-slider {
        padding: rem(15px 15px 0px);

        @media only screen and (min-width: $md) {
            padding: rem(60px 15px 0px);
        }

        @media only screen and (min-width: $lg) {
            .bx-wrapper .bx-controls-direction a {
                height: 65px;
                width: 65px;
                background-color: #ffffff;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 100%;
                top: 50%;
                transform: translateY(-50%);
                border: 1px solid #e2e2e2;
                transition: all ease-in-out .6s;
                box-shadow: -1.294px -4.83px 91.14px 1.86px rgba(161, 161, 161, 0.75);


                &.bx-prev {
                    left: -90px;
                    background-image: url("#{$folder-path}/slider-arrow-prev.png");
                }

                &.bx-next {
                    right: -90px;
                    background-image: url("#{$folder-path}/slider-arrow-next.png");
                }

                &:hover {
                    background-image: none;
                    box-shadow: -1.294px -4.83px 91.14px 1.86px rgba(161, 161, 161, 0.45);
                }
            }
        }
    }

    .owl-carousel {
        .item {
            padding: rem(0 45px);

            @include media-breakpoint-up(md) {
                 padding: rem(0 90px)
            }
        }

        .owl-nav {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin-top: rem(-25px);

            @include media-breakpoint-up(sm) {
                top: 50%;
            }

            button {
                position: absolute;
                border: 1px solid transparent;
                border-radius: 100%;
                transition: all ease-in-out .6s;
                width: rem(40px);
                height: rem(40px);
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);

                @include media-breakpoint-up(md) {
                    width: rem(70px);
                    height: rem(70px);
                }

                span {
                    font-size: rem(60px);
                    line-height: rem(30px);
                    color: #e1e6e9;

                    @include media-breakpoint-up(md) {
                        font-size: rem(80px);
                        line-height: rem(40px);
                    }
                }

                &:hover {
                    background-image: none;
                    border-color: #e8e8e8;
                    box-shadow: -1.24px -4.3px 61.14px 1.6px rgba(161, 161, 161, 0.45);

                    span {
                        color: #232323;
                    }
                }
            }

            .owl-prev{
                left: 5px
            }

            .owl-next {
                right: 5px;
            }
        }

        .owl-dots {
            position: absolute;
            bottom: rem(5px);
            left: 0;
            right: 0;
            z-index: 99;
            text-align: center;

            button {
                border-radius: 100%;
                width: rem(10px);
                height: rem(10px);
                background: $black;
                margin: rem(5px);

                &.active {
                    background: $white;
                }
            }
        }
    }

}


.porfoliodetail {
    padding: rem(25px 15px 0);
    font-size: 14px;
}


.portfolio-item:nth-child(1n + 1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
@media (min-width: 32em) {
  .portfolio-item:nth-child(2n + 1) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .portfolio-item:nth-child(2n + 2) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
}
@media (min-width: 54em) {
  .portfolio-item:nth-child(3n + 1) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .portfolio-item:nth-child(3n + 2) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  .portfolio-item:nth-child(3n + 3) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
}
@media (min-width: 91em) {
  .portfolio-item:nth-child(4n + 1) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  .portfolio-item:nth-child(4n + 2) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  .portfolio-item:nth-child(4n + 3) {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s;
  }
  .portfolio-item:nth-child(4n + 4) {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
}
.portfolio-item {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}

.backLink {
    font-family: $sans-serif-font;
    z-index: 99;
    margin-left: rem(14px);

    @include media-breakpoint-up(md) {
        position: absolute;
        top: rem(58px);
    }

    a{
        color: $black;
        font-size: rem(14px);
        transition: all ease-in-out .6s;
    
    .cricleicon {
        width: rem(30px);
        height: rem(30px);
        background: $black;
        display: inline-block;
        border-radius: 100%;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
        text-align: center;
        line-height: 30px;
        margin-right: 5px;
        transition: background ease-in-out .6s;


        .fa{
            color: $white
        }
    }

    &:hover{
        color: #0056B4;
        .cricleicon{
            background: #0056B4;
        }
    }

    }
}