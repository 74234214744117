.contactus-block {
    background: #8da0af;
    padding: rem(0 15px);

    @include media-breakpoint-up(md) {
        margin-bottom: rem(190px);
    }

    .row {
        min-height: rem(400px);

        @include media-breakpoint-down(sm) {
            padding: 0;
            min-height: 0;

            .indiaAdd {
                padding-top: 50px
            }
        }
    }


    .contactus-information {
        background: #345f81;
        color: $white;
        padding: rem(35px 0);
        font-family: $sans-serif-font;

        @include media-breakpoint-up(md) {
            padding: rem(70px 50px);
            position: absolute;
            left: 0;
            right: 0;
            top: rem(70px);
            z-index: 1;
            min-height: rem(400px);
        }

        h1,
        h3 {
            font-size: rem(30px);
            color: #ffffff;
        }

        p {
            font-size: rem(14px);
            letter-spacing: 0;
            margin-top: 0;
        }

        a {
            color: #ffffff;
        }
    }

    .section-tag {
        font-size: rem(14px);
        position: relative;
        margin: rem(0 0 30px 15px);
        line-height: 20px;
        color: #393939;
        text-rendering: optimizeLegibility;
        color: #ffffff;

        @include media-breakpoint-up(md) {
            position: absolute;
            left: 3.1735rem;
            top: rem(80px);
            width: rem(130px);
        }
    }

    .section-tag::before {
        content: "";
        background: $white;
        height: rem(1px);
        width: rem(50px);
        position: absolute;
        display: inline-block;
        left: 0;
        bottom: rem(-12px);
    }
}

.map-link {
    background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/map-icon.png);
    background-position: 0px center;
    background-repeat: no-repeat;
    display: inline-block;
    padding-left: 25px;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    color: #b1c0c4;
    text-rendering: optimizeLegibility;
    letter-spacing: 2px;
    margin-top: 1rem;
}

.map-modal {
    .modal-dialog {
        max-width: 90vw;

        .modal-content {
            min-height: 90vh;
        }
    }
}

.googleMap-iframe {
    height: 100%;
    width: 100%;
    position: absolute;
}