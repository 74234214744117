.form-section {
    padding-top: 29px;
    max-width: 325px;
}

.form-group {
    margin-bottom: 30px;

    label {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
    }
}

.form-control {
    background: white no-repeat;
    border-width: 0;
    border-radius: 0;
    border-color: $input-border-color;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background-image: linear-gradient(72deg, #693680 0%, #00A3B7 40%, #30C388 100%), linear-gradient(to bottom, silver, silver);
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    transition: background-size 0.4s cubic-bezier(0.44, 0.07, 0.06, 1);

    &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
    }

    &.is-invalid-input{
       background-image: linear-gradient(72deg, #f50627 0%, #f58c84 40%, #ec0525 100%), linear-gradient(to bottom, silver, silver);
       background-size: 100% 1px, 100% 1px;
    }
    
    &:focus {
        box-shadow: none;
        background-size: 100% 1px, 100% 1px;
        outline: none;
    }
}

textarea.form-control {
    height: 140px;
    color: $darkest-grey;
    padding-top: 0
}

.btn {
    background-color: transparent;
    border: 1px solid $input-border-color;
    border-radius: rem(70px);
    font-size: 14px;
    font-weight: 700;
    font-family: $sans-serif-font;
    text-transform: uppercase;
    color: $darkest-grey;
    padding: rem(5px 15px 2px);
    line-height: 1.6;
    letter-spacing: rem(2px);
    transition: background-color .25s ease-out, color .25s ease-out;

    &:before,
    &:after {
        content: "";
        height: 1px;
        position: absolute;
        transition: all 0.3s linear;
        background: $black;
        transition-delay: 0s;
    }

    span {
        &:before {
            left: -1px;
            top: -1px;
            bottom: 101%;
        }

        &:after {
            right: -1px;
            bottom: -1px;
            top: 101%;
        }
    }

    &:hover,
    &:focus {
        background-color: transparent;
        border-color: transparent;
        background-image: linear-gradient(to bottom, #3d159f 0%, #581b96 100%);
        color: $white;

        &:befor,
        &:after {
            transition-delay: 0.2s;
        }

        span {

            &:before,
            &:after {
                transition-delay: 0s;
            }
        }
    }

    &:focus {
        outline: none;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }

    &--white {
        border-color: $white;
        background: $white;
    }

    &--black {
        background-color: #373737;
        color: $white
    }

    &--red {
        background: transparent linear-gradient(90deg, #E93843 0%, #F5888F 100%) 0% 0% no-repeat padding-box;
        color: $white;
        border-color: transparent;
    }

    &--large {
        font-size: 1rem;
        margin: 0;
        padding: 0.6rem 2em;
        border-radius: 30px;
        letter-spacing: rem(4px);
    }
}


button:focus {
    outline: none
}

.animate-box {
    position: relative;
    margin-bottom: 46px;

    label {
        position: absolute;
        top: 2px;
        transition: .2s ease-out;
        font-size: 16px;
        line-height: 30px;
        z-index: 1;
        left: 0;
        pointer-events: none;
        text-transform: uppercase;
        color: #fff;
    }

    .form-control {
        border-color: $white;
        color: $white;
    }
}

.textarea-box .form-control {
    color: $darkest-grey;
}

.has-content label {
    font-size: 14px;
    font-weight: 700;
    transform: translateY(-90%)
}

.fullborderform {
    .form-control {
        background-color: #e1e6e9;
        border: 1px solid #e1e6e9;
        background-image: none;
        box-shadow: none;
        font-size: 1rem;
        color: #4c4c4c;
        height: 2.875rem;
        margin: 0;
        padding: 0.5rem 1rem;

        &::placeholder {
            color: $input-placeholder-color;
            opacity: 1;
        }

        &:focus {
            background-color: #e1e6e9;
            border-color: #e1e6e9;
            box-shadow: none;
            background-image: linear-gradient(72deg, #693680 0%, #00A3B7 40%, #30C388 100%), linear-gradient(to bottom, silver, silver);
        }

        &.is-invalid-input {
            color: red;
            border-color: red;
            background-image: none;
            &:focus {}
        }
    }

    textarea.form-control {
        height: auto;
    }
}

.contactus-form-section {
    background: #f8f8f8;
    padding: rem(39px 15px 32px 15px);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem(80px);
    }

    .col {
        min-width: 100%;

        @include media-breakpoint-up(sm) {
            min-width: 0
        }
    }
}

.form-row {

    .col,
    [class*="col-"] {
        padding-right: rem(8px);
        padding-left: rem(8px);
    }

    .form-group {
        margin-bottom: rem(16px);
    }
}

.has-danger p,
.has-danger small,
.form-control.is-invalid-input,
.is-invalid-input {
    color: red;
    border-color: red
}