.homeBannerSection {
    position: relative;
    margin-top: 30px;

    @include media-breakpoint-up(lg) {
        margin-left: rem(-50px);
        margin-right: rem(-50px);
        margin-top: 65px;
    }

    .section-title-large {
        font-size: 33px;
        text-align: center;
    }

    .bannerParallaxSection {

        ul {

            list-style: none;
            min-height: 78vh;

            li {
                position: absolute;
                -webkit-pointer-events: none;
                pointer-events: none;
                user-select: none;

                img {
                    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.3);
                }

                &:nth-of-type(2) {
                    left: 59.5% !important;
                    top: 42% !important;
                }

                &:nth-of-type(3) {
                    left: 26.2% !important;
                    top: 41% !important;
                }

                &:nth-of-type(4) {
                    left: inherit !important;
                    right: 20%;
                    top: 45% !important;
                }

                &:nth-of-type(5) {
                    left: 31.7% !important;
                    top: 20% !important;
                }

                &:nth-of-type(6) {
                    left: 62% !important;
                    top: 15% !important;
                }

                &:nth-of-type(7) {
                    left: inherit !important;
                    right: -1%;
                    top: -8% !important;
                }

                &:nth-of-type(8) {
                    left: -1% !important;
                    top: inherit !important;
                    bottom: 5%;
                }

                &:nth-of-type(9) {
                    left: inherit !important;
                    right: 14%;
                    top: 2% !important;
                }

                &:nth-of-type(10) {
                    left: 19% !important;
                    top: 10% !important;
                }

                &:nth-of-type(11) {
                    left: 3% !important;
                    top: -7% !important;
                }

                &:nth-of-type(12) {
                    left: 11% !important;
                    top: 42% !important;
                }

                &:nth-of-type(13) {
                    left: inherit !important;
                    right: 5.5%;
                    top: 36% !important;
                }

                &:last-of-type {
                    left: 40% !important;
                    top: 5% !important;

                    img {
                        -webkit-border-radius: 32px;
                        -moz-border-radius: 32px;
                        border-radius: 32px;
                    }
                }
            }
        }
    }


    @media only screen and (max-width:1025px) {
        .bannerParallaxSection ul {
            min-height: 500px;
            width: 100%;
            margin: 0
        }

        .bannerParallaxSection ul li img {
            max-width: 80%
        }

        .engagementModelListing li>div h4 {
            min-height: 56px;
        }
    }

    @media only screen and (max-width:821px) {
        .bannerParallaxSection {
            overflow-x: auto;
            width: 100%;
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .section-title-large {
            font-size: 22px;
        }

        .bannerParallaxSection ul {
            min-height: 340px;
            width: 740px;
            margin: 0;
        }

        .bannerParallaxSection ul li img {
            max-width: 80%;
        }

        .bannerParallaxSection ul li:nth-of-type(2) {
            left: 57.5% !important;
            top: 18% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(3) {
            left: 39.2% !important;
            top: 59% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(4) {
            left: inherit !important;
            right: 27%;
            top: 48% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(5) {
            left: 38.7% !important;
            top: 37% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(6) {
            left: 44% !important;
            top: 2% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(7) {
            left: inherit !important;
            right: -1%;
            top: -8% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(8) {
            left: -1% !important;
            top: inherit !important;
            bottom: -9%;
        }

        .bannerParallaxSection ul li:nth-of-type(9) {
            left: inherit !important;
            right: 14%;
            top: 2% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(10) {
            left: 20% !important;
            top: 7% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(11) {
            left: 2% !important;
            top: -2% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(12) {
            left: 16% !important;
            top: 51% !important;
        }

        .bannerParallaxSection ul li:nth-of-type(13) {
            left: inherit !important;
            right: 5.5%;
            top: 36% !important;
        }

        .bannerParallaxSection ul li:last-of-type {
            left: 40% !important;
            top: 5% !important;
            display: none !important
        }
    }

}


.work-carousel-navs {
    position: relative;
    float: none !important;

    @include media-breakpoint-down(md) {
        display: none
    }

    div[id*="Arrow-"] {
        width: 175px;
        height: 175px;
        position: absolute;
        cursor: pointer;
        z-index: 9;
        display: none;
    }
}

#Arrow-Next {
    right: -90px;
    bottom: -37px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/button-next.png);
}

#Arrow-Previous {
    left: -90px;
    bottom: -37px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/button-prev.png);
}

.work-section-carousel {
    .owl-carousel {
        .owl-item {
            img {
                max-width: 100%;
                max-height: 100%;
            }

            .work-carousel-content {
                img {
                    width: auto;
                }
            }
        }
    }
}

.work-carousel-content {
    position: absolute;
    bottom: 50px;
    left: 50px;
    text-align: left;

    h3 {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 700;
        color: #ffffff;
        line-height: 27px;
        margin: 0;
        padding: 24px 0 17px 0;
    }

    p {
        color: #ffffff;
        line-height: 20px;
        width: 235px;
    }
}


.modelBlocks {
    .engagementModelListing {
        list-style: none;
        margin: 55px -5px 0;
        padding: 0;
    }
}

.engagementModelSection {
    .columns {
        &:last-child {
            &:not(:first-child) {
                float: left;
            }
        }
    }
}

.engagementModelListing {
    li {
        padding: 5px;

        &>div {
            padding: 30px;
            height: 100%;

            @include media-breakpoint-down(md) {
                padding: rem(15px);
            }

            &.fixPriceBlock {
                background-color: #345f80;

                .icon {
                    background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/fixedPriceProjectIcon.png);
                }
            }

            &.timeBasisBlock {
                background-color: #8da0af;

                .icon {
                    background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/timeBasisIcon.png);
                }
            }

            &.hireTeamBlock {
                background-color: #a3a3a3;

                .icon {
                    background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/hireTeamIcon.png);
                }
            }

            * {
                color: #fff;
            }

            .icon {
                display: block;
                width: 57px;
                height: 57px;
                background-color: transparent;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 30px;
            }

            h4 {
                font-size: 20px;
                letter-spacing: 0.5px;
                margin-bottom: 18px;
            }

            p {
                margin-bottom: 18px;
            }

            .modelBoxAction {
                a {
                    display: inline-block;
                    font-family: 'Roboto', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    position: relative;
                    background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/white-readmore.png);
                    background-repeat: no-repeat;
                    background-position: 100% 30%;
                    padding-right: 25px;
                }
            }
        }
    }
}

@media only screen and (max-width:600px) {
    .engagementModelListing li {
        min-width: 100%
    }
}

.whatwedo-section {
    padding: rem(0 15px);

    div[class*="apps-section"] {
        padding: 50px 15px;
        position: relative;
        min-height: 360px;

        @include media-breakpoint-down(sm) {
            padding: rem(15px 0);
            min-height: rem(190px);
        }

        .section-info {
            position: relative;
            top: 0;
            width: 208px;

            h3 {
                font-size: 18px;
                font-weight: 900;
                color: #cecece;
                margin: 0;
                padding: 19px 0 0 0;
                font-family: 'Roboto', sans-serif;
                text-transform: uppercase;
            }

            p {
                position: relative;
                margin: 0;
                line-height: 20px;
                color: #393939;

                &:before {
                    font-size: 18px;
                    font-weight: 900;
                    color: #cecece;
                    margin: 0;
                    padding: 19px 0 0 0;
                    font-family: 'Roboto', sans-serif;
                    text-transform: uppercase;
                }
            }
        }

        .btn--large {
            margin-top: 20px !important
        }
    }

}

.inner-tab-content {
    height: 180px;
    overflow: auto;
    cursor: url("https://s3-ap-southeast-1.amazonaws.com/singsys/img/openhand.cur"), move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.inner-tab-content,
.inner-tab-content strong,
.inner-tab-content a {
    font-size: 14px;
    line-height: 27px;
    text-rendering: optimizeLegibility;
}

.techs-info-tabbing {
    .tabs {
        background: transparent;
        border: none;
        padding-top: 35px;
        flex-direction: column;

        &>li {
            &.tabs-title {
                float: none;
                text-align: left;
                display: block;

                a {
                    border: none;
                    font-size: 26px;
                    color: #383838;
                    line-height: 36px;
                    padding: 0;
                    display: inline-block;
                    background: transparent;
                    transition: all 0.3s ease 0s;

                    @include media-breakpoint-down(sm) {
                        line-height: rem(26px);
                        font-size: rem(16px);
                    }

                    @media only screen and (max-width: 380px) {
                        font-size: rem(13px);
                    }

                    &.active {
                        font-weight: 700;

                        @include media-breakpoint-down(sm) {
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }

    .tab-content {
        background: transparent;
        padding: 0;
        border: none;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .tab-pane {
        padding: 33px 0 0 0;
    }
}

.web-apps-section {
    @include media-breakpoint-down(sm) {
        background-color: #e5e5e5 !important;
        background-repeat: no-repeat;
        background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/payroll-webapp.png);
        margin-top: 0 !important;
        background-size: 40% auto;
        background-position: right 30%;
    }

    .techs-info-tabbing {
        .tabs {
            padding-top: 0;
        }
    }
}

.mobile-apps-section {
    @include media-breakpoint-down(sm) {
        background-repeat: no-repeat;
        background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/ingram-app.png);
        background-size: auto 90%;
        background-position: 94% bottom;

        .btn--large {
            display: none;
        }
    }

    @include media-breakpoint-down(xs) {
        background-size: auto 80%;
    }

}

.gray-bg {
    background-color: #f8f8f8;
}

.stick-to-edge {
    position: absolute;
    right: rem(-15px);
    max-width: 50%;
    top: 50%;
    transform: translateY(-50%);

    video {
        max-width: 100%
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.tabcontentBlock {
    max-width: 48%;

    @include media-breakpoint-down(sm) {
        max-width: 100%;
    }
}

.first-video {
    max-width: 400px;

    .videoWrapper {
        padding-bottom: 125%
    }

    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.whatwedo-image {
    @include media-breakpoint-down(md) {
        display: none;
    }

    video {
        max-height: 530px;
    }
}

.whatwedo__btn {
    @include media-breakpoint-down(md) {
        order: 2;
        margin-top: 10px;
    }
}

.adword-section {
    padding: 0 15px;

    @media only screen and (min-width: 1025px) {
        width: calc(100% + 100px);
        margin: 0 -50px;
    }

    .adword-slider-wrapper {
        .left-navigation {
            width: 100%;
            position: relative;
            background-color: #333333;
            padding: 20px 0 0;

            @media only screen and (min-width: $lg) {
                width: 35%;
                padding: 80px 0px;
            }

            @media only screen and (min-width: 1025px) {

                .pager-section {
                    &:after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        background-position: center;
                        transform: translateY(-50%);
                        right: 0;
                        background-image: url(../../images/service-banner/layer.png);
                        left: 0;
                        height: 62px;
                        border-top: 1px solid #484848;
                        border-bottom: 1px solid #484848;
                        pointer-events: none;
                        display: block;
                    }
                }

                .sectionHeading h2 {
                    color: $white;
                    margin-bottom: 50px
                }

                .slider-text {
                    text-align: center;

                    h3 {
                        color: $white;
                        margin: 0;
                        height: rem(64px);
                        padding: rem(15px 0);
                        line-height: rem(34px);
                        font-weight: 400;
                        transition: all 0.4s ease-in-out;
                        font-family: $sans-serif-font;
                    }
                }

                .extraClassnext {
                    .slider-text {
                        h3 {
                            font-size: 23px;
                            color: #8B8B8B;
                            opacity: 0.9;
                        }
                    }
                }

                .extraClass {
                    .slider-text {
                        h3 {
                            font-size: 23px;
                            color: #8B8B8B;
                            opacity: 0.9;
                        }
                    }
                }

                .extraprev {
                    .slider-text {
                        h3 {
                            font-size: 18px;
                            color: #5B5B5B;
                            opacity: 0.8;
                        }
                    }
                }

                .extraClassnext-2 {
                    .slider-text {
                        h3 {
                            font-size: 18px;
                            color: #5B5B5B;
                            opacity: 0.8;
                        }
                    }
                }

                .slick-current.slick-active.slick-center {
                    .slider-text {
                        h3 {
                            font-size: 28px;
                            font-weight: 400;
                            color: #E6E6E6;
                        }
                    }
                }
            }

            @media only screen and (max-width: 1024px) {

                .pager-section {}

                .sectionHeading h2 {
                    color: $white;
                    margin-bottom: 10px
                }

                .slider-text {
                    text-align: center;
                    padding: rem(0 2px);

                    h3 {
                        color: $white;
                        margin: 0;
                        padding: rem(10px);
                        font-weight: 400;
                        transition: all 0.4s ease-in-out;
                        font-family: $sans-serif-font;
                        border-bottom: 4px solid transparent;
                        font-size: rem(13px);
                    }
                }

                .slick-current.slick-active {
                    .slider-text {
                        h3 {
                            border-bottom: 4px solid $white;
                        }
                    }
                }
            }
        }


        .pager-content {
            width: 100%;
            padding: 0;

            @media only screen and (min-width: $lg) {
                width: 65%;
            }

            .slick-slider,
            .slick-track,
            .slick-list,
            .slick-slide>div {
                height: 100%;
            }

            .overlay-content-box {
                height: 100%;

                .overlay-content-image {
                    height: 100%;

                    position: relative;

                    >img {
                        width: 100%;

                        @media only screen and (min-width: $lg) {
                            width: auto
                        }
                    }

                    .overlay-content {
                        padding: rem(15px);
                        font-size: rem(14px);
                        background-color: $white;
                        color: #333333;
                        margin: 0;
                        font-family: $sans-serif-font;
                        line-height: 1.4;

                        @media only screen and (min-width: 1025px) {
                            padding: rem(0 30px 20px);
                            font-size: 19px;
                            color: $white;
                            position: absolute;
                            bottom: 0;
                            background-color: transparent;
                            background-image: linear-gradient(to top, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 10%, rgba(0, 0, 0, 0) 100%);
                        }

                        h3 {
                            font-size: 20px;
                            color: $black;
                            font-weight: 400;
                            margin-bottom: ren(10px);
                            font-family: $sans-serif-font;

                            @media only screen and (min-width: 1025px) {
                                font-size: 26px;
                                color: $white;
                            }
                        }

                        >.content-image {
                            display: none;

                            @media only screen and (min-width: 1025px) {
                                padding-bottom: 29px;
                                display: block;
                            }
                        }

                        .showSingle {
                            font-size: 12px;
                            font-style: italic;
                            color: #0008FF;
                            font-family: $serif-font;
                            max-width: 80px;
                            cursor: pointer;

                            @media only screen and (min-width: 1025px) {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}

.contentPopup {
    position: absolute;
    top: 101%;
    right: 0;
    left: 0;
    bottom: 0;
    background: $white;
    padding: rem(0px);
    font-family: $sans-serif-font;
    max-height: 0;
    overflow: hidden;
    height: 0;
    transition: .9s cubic-bezier(0, 1, 0.5, 1);

    &__title {
        margin-bottom: rem(20px);
        padding: rem(15px);

        @media only screen and (min-width: 1025px) {
            margin-bottom: rem(5px);
            padding: rem(15px 45px);
        }

        .closeBtn {
            span {
                font-size: 24px;
                transform: scaleX(1.5);
                display: inline-block;
                cursor: pointer;
                line-height: .9;
                transition: color 0.4s ease-in-out;

                @media only screen and (min-width: 1025px) {
                    font-size: 40px;
                }

                &:hover {
                    color: #0056b3;
                }
            }
        }
    }

    &__detail {
        border-bottom: 2px solid #e8e7e7;
        max-height: calc(100% - 60px);
        padding: rem(15px);
        overflow-y: auto;

        @media only screen and (min-width: 1025px) {
            border-bottom: none;
            padding: rem(15px 45px);
        }
    }

}

.openPopup {
    max-height: 100%;
    height: 100%;
    top: 0;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #ebebeb;
}

::-webkit-scrollbar-thumb {
    background: #6d6d6d;
}


.banner-image-carousel .owl-thumbs {
  display: none;
}
.homeBannerSection .banner-image-carousel .owl-dots {
    text-align: center;
    margin: 10px 0px;
}


.homeBannerSection  .banner-main-heading {
    font-size: 26px;
    color: #353535;
    /*padding-bottom: 27px;*/
    position: relative;
    margin-bottom: 15px;
    line-height: 40px;
}

section.homeBannerSection.web-banner-section {
    margin-top: 60px;
}

.homeBannerSection .banner-main-heading strong {
    font-size: 28px;
}

/*.homeBannerSection .banner-main-heading::after {
    content: '';
    position: absolute;
    width: 78px;
    height: 3px;
    background-color: #000000;
    bottom: 0px;
    left: 0;
    right: 0px;
    margin: 0 auto;
 }*/

.homeBannerSection p {
    font-size: 20px;
    color: #000000C7;
}


.banner-images-box .image-list>ul {
    padding: 0px;
    list-style-type: none;
    display: flex;
    margin: 0 5px;
    justify-content: center;
    align-items: flex-start;
}

.banner-images-box .image-list>ul>li {
    display: inline-block;
    margin: 5px;
}

.banner-images-box .image-list ul li .box {
    height: auto;
    min-height: auto;
}

.banner-images-box .image-list ul li .box.box1 {
    margin-bottom: 10px;
}

.banner-images-box .image-list ul li .box img {
    display: block;
}
.banner-images-box .image-list ul li .box.box1 ul {
    margin: 0px;
}

.banner-images-box .image-list ul li .box.box1 ul li {
    margin: 0px;
}

.banner-images-box .image-list ul li .box.box1 ul li:first-child {
    margin-right: 10px;
}

.banner-images-box .image-list ul li .box.box4 img {
    margin-bottom: 10px;
}

.banner-images-box .image-list ul li .box.box4 img:last-child {
    margin-bottom: 0px;
}

.banner-images-box .image-list ul li .box.box5 img:first-child {
    margin-bottom: 0px;
}


.banner-images-box .image-list ul li .box.box6 img {
    margin-bottom: 10px;
}

.banner-images-box .image-list ul li .box.box6 img:last-child {
    margin-bottom: 0px;
}

.banner-images-box .image-list ul li .box.box7 img:first-child {
    margin-bottom: 0px;
}

.banner-images-box {
    margin-top: 25px;
}

.mobile-banner-section{
    display: none;
}

.homeBannerSection .banner-image-carousel .owl-dots .owl-dot span {
    background-color: #fff;
    border: 1px solid #CACACA;
    width: 14px;
    height: 14px;
    margin: 0px 6px;
    display: block;
    border-radius: 50%;
}

.homeBannerSection .banner-image-carousel .owl-dots .owl-dot.active span {
    background-color: #BA2328;
    border-color: #BA2328;
}

.banner-images-box .image-list ul li .box img {
    filter: unset;
    transform: unset;
}


.banner-images-box .image-list>ul>li ul li {
    margin: 0px;
}

.banner-images-box .image-list ul li .box.box4 ul li {
    margin-bottom: 10px;
}

.banner-images-box .image-list ul li .box.box6 ul li {
    margin-bottom: 10px;
}

.banner-images-box .image-list ul li .box.box6 ul li:last-child {
    margin: 0px;
}

.banner-images-box .image-list ul li .box.box4 ul li:last-child {
    margin: 0px;
}

.banner-images-box .image-list ul li .box.box5 ul li {
    margin-bottom: 9px;
}

.banner-images-box .image-list ul li .box.box5 ul li:last-child {
    margin: 0px;
}

.banner-images-box .image-list ul li .box.box7 ul li {
    margin-bottom: 7px;
}

.banner-images-box .image-list ul li .box.box7 ul li:last-child {
    margin: 0px;
}



.image-list .box ul{
   margin: 0;
   padding: 0;
   display: inline-grid;
}
.image-list .box.box1 ul{
   display: flex;
}
.image-list .box li{
   position: relative;
   display: inline-block;
   margin: 5px 0;
}

.banner-images-box .image-list ul li .box img:hover {
    transform: scale(1.1);
}

.banner-images-box .image-list>ul>li ul li {
    overflow: hidden;
}

/*.image-list .box li:not(:hover)::before{
   position: absolute;
   content: '';
   width: 100%;
   height: 100%;
   transform: scale(0);
   transform-origin: top left;
   transition: transform 0.8s;
   z-index: 1;
   background-color: rgba(0, 0, 0, 0.4);
}

.image-list:hover .box li::before{
   transform: scale(1);
   transform-origin: bottom right;
}

.image-list:hover .box li:hover::before{
   transform: scale(0);
   transform-origin: top left;
}

.image-list:hover .box li:not(:hover) img{
   filter: blur(2px) grayscale(100%);
}*/









@media (max-width: 1199px){
    .homeBannerSection {
        margin-left: 0;
        margin-right: 0;
        margin-top: 65px;
    }
}

@media only screen and (max-width:991px){
    .banner-images-box .image-list ul li .box.box1 {
        margin-bottom: 6px;
    }
    .banner-images-box .image-list ul li .box.box4 ul li {
        margin-bottom: 7px;
    }
    .banner-images-box .image-list ul li .box.box5 ul li {
        margin-bottom: 6px;
    }
    .banner-images-box .image-list ul li .box.box6 ul li {
        margin-bottom: 7px;
    }
    .banner-images-box .image-list ul li {
        margin: 5px 3px;
    }
    .banner-images-box .image-list ul li .box.box7 ul li {
        margin-bottom: 6px;
    }
    .banner-images-box {
        margin-top: 30px;
    }
    .banner-images-box .image-list ul li .box.box1 ul li:first-child {
        margin-right: 6px;
    }
    section.homeBannerSection.web-banner-section {
        margin-top: 10px;
    }
    .homeBannerSection p {
        margin-bottom: 0px;
    }
     section.homeBannerSection.web-banner-section {
        margin-top: 0px;
    }
    .homeBannerSection p {
        margin-bottom: 0px;
    }
    .homeBannerSection .banner-main-heading {
        line-height: 35px;
        margin-bottom: 0px;
    }
}

@media only screen and (max-width:767px){
    section.homeBannerSection.web-banner-section {
        margin-top: 15px;
    }
    .banner-images-box {
        margin-top: 15px;
    }
    .homeBannerSection .banner-main-heading strong {
        font-size: 22px;
    }
    .homeBannerSection .banner-main-heading {
        font-size: 22px;
        margin-bottom: 0px;
    }
    .homeBannerSection p {
        font-size: 18px;
        margin-bottom: 0px;
    }
    .banner-images-box .image-list ul li .box.box4 ul li {
        margin-bottom: 6px;
    }
    .banner-images-box .image-list ul li .box.box6 ul li {
        margin-bottom: 6px;
    }
}



@media only screen and (max-width:576px){
    .web-banner-section{
        display: none;
    }
    .mobile-banner-section{
        display: block;
    }
    .mobile-banner-section .banner-main-heading::after {
        display: none;
    }

    .mobile-banner-section .banner-main-heading {
        font-size: 22px;
        text-align: left;
        padding: 0 15px;
        line-height: 30px;
        margin-bottom: 10px;
    }

    .mobile-banner-section .banner-main-heading strong {
        font-size: 22px;
    }

    .banner-image-carousel {
        padding: 0 15px;
    }
    .mobile-banner-section {
        margin-top: 5px;
    }

}
