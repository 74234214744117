.accordionTab {
    font-size: rem(14px);

    .nav-tabs {
        display: none;
    }

    @media (min-width: 768px) {
        .nav-tabs {
            display: flex;
        }

        .card {
            border: none;

            .card-header {
                display: none;
            }

            .collapse {
                display: block;
            }
        }
    }

    @media (max-width: 767px) {
        .tab-content>.tab-pane {
            display: block !important;
            opacity: 1;
            margin-top: rem(-3px);

            .card-header {
                padding: 0;

                a {
                    color: #374e62;
                    font-family: $sans-serif-font;
                    font-size: rem(16px);
                    display: block;
                    padding: rem(15px);
                }
            }
        }
    }
}

.verticalTab {
    .nav-tabs {
        flex-direction: column;
        border-bottom: none;

        .nav-item {
            display: block;

            &:not(:last-child) {
                border-bottom: 1px solid #ffffff;
            }

            .nav-link {
                border: none;
                color: #374e62;
                font-family: $sans-serif-font;
            }
        }
    }

    .tab-content {
        .card-body {
            padding: rem(15px);

            @include media-breakpoint-up(md) {
                padding: rem(0 0 0 50px);
            }

            h2 {
                font-size: rem(20px);
                margin-bottom: rem(15px);

                @include media-breakpoint-up(md) {
                    font-size: rem(36px);
                    margin-bottom: rem(25px);
                }
            }
        }
    }
}

.aws-navbar {
    padding: rem(15px 25px);

    @include media-breakpoint-up(md) {
        background: #eeebeb;
    }

    h3 {
        font-family: $sans-serif-font;
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;
        color: #374e62;
        margin: 0 0 10px;
        padding: rem(15px 0 8px);
        border-bottom: 2px solid #ffffff;

        @include media-breakpoint-up(md) {
            margin: 0 0 20px;
        }
    }
}

.modelBoxAction.auditReport {
    position: fixed;
    right: rem(-90px);
    top: 50%;
    z-index: 9;
    transform: translateY(-50%) rotate(-90deg);
    width: rem(222px);
    height: rem(45px);
    a {
        padding: rem(15px 10px);
        color: #ffffff;
        font-family: $sans-serif-font;
        font-weight: $font-weight-bold;
        border-radius: rem(4px);
        white-space: nowrap;
        text-align: center;
    }
}

.fullSummary-services .readMoreBtn {
    text-align: left;
    margin-top: 25px
}

.fullSummary-services div.collapse:not(.show) {
    height: 333px !important;
    overflow: hidden;
    display: block
}

@media (min-width:768px) {
    .fullSummary-services div.collapse:not(.show) {
        height: 353px !important
    }
}

.fullSummary-services div.collapsing {
    min-height: 333px !important
}

@media (min-width:768px) {
    .fullSummary-services div.collapsing {
        min-height: 353px !important
    }
}

.fullSummary-services a.collapsed:after {
    content: '+ Read More'
}

.fullSummary-services a:not(.collapsed):after {
    content: '- Read Less'
}




.process-flowbox .process-flowbox_detail {
    background-color: #F5F5F5;
    padding: 60px 70px !important;
}

.process-flowbox h4 {
    text-align: center;
    color: #374E62;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 60px;
}

.process-flowbox h4 {
    text-align: center;
    color: #374E62;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 60px;
}

.process-flowbox .process-flowbox_detail {
    background-color: #F5F5F5;
    padding: 60px 70px !important;
}

.designProcessTab .studioHeading {
    font-size: 40px;
    padding-bottom: 40px;
}

.designProcessTab ul#tabs {
    max-width: 100%;
    display: flex;
    box-shadow: none;
    flex-direction: unset;
    border-top: 0px !important;
    border-bottom: 1px solid #CECECE;
    margin-bottom: 40px;
}

.designProcessTab ul#tabs li {
    margin-right: 10px;
}

.designProcessTab ul#tabs li a {
    border: 0px;
    background-color: #EEEEEE;
    border-radius: 6px 6px 0px 0px;
    padding: 15px 20px;
}

.designProcessTab ul#tabs li a h4 {
    color: #747474;
    font-size: 20px;
}

.designProcessTab div#content {
    padding: 0px;
}


.designProcessTab ul#tabs li a.active {
    background-color: #374E62;
}

.designProcessTab ul#tabs li a.active h4 {
    color: #fff;
}


.process-flowbox h4 {
    text-align: center;
    color: #374E62;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 60px;
}


.process-flowbox .process-flowbox_detail {
    background-color: #F5F5F5;
    padding: 60px 70px !important;
}

.designProcessTab .studioHeading {
    font-size: 40px;
    padding-bottom: 40px;
}

.designProcessTab ul#tabs {
    max-width: 100%;
    display: flex;
    box-shadow: none;
    flex-direction: unset;
    border-top: 0px !important;
    border-bottom: 1px solid #CECECE;
    margin-bottom: 40px;
    flex-wrap: unset;
}

.designProcessTab ul#tabs li {
    margin-right: 10px;
}

.designProcessTab ul#tabs li a {
    border: 0px;
    background-color: #EEEEEE;
    border-radius: 6px 6px 0px 0px;
    padding: 15px 20px;
}

.designProcessTab ul#tabs li a h4 {
    color: #747474;
    font-size: 20px;
}

.designProcessTab div#content {
    padding: 0px;
}


.designProcessTab ul#tabs li a.active {
    background-color: #374E62;
}

.designProcessTab ul#tabs li a.active h4 {
    color: #fff;
}

.functional-testing-box {
    background-color: #F5F5F5;
    padding: 60px 60px 40px;
    margin: 50px 0px;
}

.functional-testing-box h4 {
    text-align: center;
    color: #000000;
    font-size: 25px;
}

.functional-testing-box p {
    color: #595959;
    font-size: 13px;
    max-width: 580px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 40px;
}

.functional-testing-box ul.list-view {
    display: flex;
    flex-wrap: wrap;
}

.functional-testing-box ul.list-view li {
    padding: 37px 20px 30px;
    width: 31.33%;
    background-color: #fff;
    margin: 0 10px 30px;
    border-radius: 10px;
}

.functional-testing-box ul.list-view li::before {
    display: none;
}

.functional-testing-box ul.list-view li.active {
    background-color: #0A5CA3;
    box-shadow: 0px 3px 30px #00000029;
}

.functional-testing-box ul.list-view li h4 {
    font-size: 18px;
    text-align: left;
    margin: 24px 0px 10px;
}

.functional-testing-box ul.list-view li p {
    margin: 0px;
    text-align: left;
    color: #595959;
    font-size: 13px;
}

.functional-testing-box ul.list-view li.active h4, 
.functional-testing-box ul.list-view li.active p {
    color: #fff;
}

.designProcessTab ul#tabs {
    min-width: max-content;
    margin: 0px;
}

.tabbox {
    overflow: auto;
    margin-bottom: 40px;
}

.carouselbox {
    margin: 60px 0px;
}

.carouselbox h4 {
    text-align: center;
    margin-bottom: 40px;
    font-size: 25px;
}

.carouselbox .item {
    text-align: center;
}

.carouselbox .item img {
    object-fit: contain;
}

.carouselbox .owl-item:nth-child(odd) .item {
    background-color: #EFEFEF;
}

.case-studies ul.case-studies-content {
    padding: 0px;
    list-style-type: none;
}

.case-studies ul.case-studies-content li {
    border-bottom: 1px solid #CECECE;
    padding-bottom: 30px;
    margin-bottom: 30px;
}

.case-studies ul.case-studies-content li h5 {
    color: #000000;
    font-size: 18px;
    font-weight: 700;
}

.case-studies ul.case-studies-content li p {
    color: #4C4C4C;
    font-size: 16px;
}

.case-studies ul.case-studies-content li a {
    color: #1272C6;
    font-size: 16px;
    text-decoration: underline;
}

.case-studies ul.case-studies-content li:last-child {
    border: 0px;
}

.processFlow {
    background: transparent !important;
    padding: 0 !important;
}

.processFlow .processflow-img {
    position: relative;
}

.processFlow .processflow-img h4 {
    position: absolute;
    left: 0px;
    right: 0px;
    text-align: center;
    padding-top: 60px;
    font-size: 25px;
    color: #374E62;
    font-weight: 700;
}

.accordionbox .accordion {
  background-color: #F2F5F6;
  color: #000000;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: 1px solid transparent;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.4s;
  border-radius: 6px 6px 0px 0px;
  position: relative;
  display: block;
  font-weight: 700;
  text-decoration: none !important;
}

.accordionbox .accordion.active {
  background-color: #F2F5F6;
  font-weight: 700;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid transparent;
}

.accordionbox .panel {
  padding: 0 18px;
  display: none;
  background-color: #F2F5F6;
  overflow: hidden;
  border-radius: 0px 0px 6px 6px;
}

.accordionbox .panel p {
    font-size: 14px;
    color: #000;
}

.accordionbox{
    margin-bottom: 30px;
}

.designProcessTab .headingCaption h3 {
    color: #000;
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 40px;
}

.designProcessTab .headingCaption {
    font-style: unset;
}

.accordionbox-content h4 {
    text-align: center;
    font-size: 25px;
    margin-bottom: 40px;
}

.accordionbox .collapsed::before {
    content: '';
    position: absolute;
    background-image: url(../images/add.svg);
    width: 25px;
    height: 26px;
    background-repeat: no-repeat;
    background-size: 100%;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.accordionbox-content .accordion .accordionbox .accordion::after {
    content: '';
    position: absolute;
    background-image: url(../images/minus.svg) !important;
    width: 25px;
    height: 26px;
    background-repeat: no-repeat;
    background-size: 100%;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.accordionbox-content .accordionbox .card-body ul {
    padding: 0px;
    list-style-type: none;
}

.accordionbox-content .accordionbox .card-body ul li {
    position: relative;
    padding-left: 60px;
    text-rendering: optimizeLegibility;
    margin: .4375rem 0;
}

.accordionbox-content .accordionbox .card-body ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 12px;
    width: 47px;
    height: 2px;
    background: #747474;
}


.accordionbox .accordion.active::after{
    display: none;
}


.carouselbox .owl-carousel .owl-dots {
    margin-top: 40px !important;
    text-align: center;
}


.carouselbox .owl-carousel .owl-dots .owl-dot span {
    margin: 2px;
    background: #1272C6 !important;
    opacity: 0.4;
    width: 10px;
    height: 10px;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 50%;
}

.carouselbox .owl-carousel .owl-dots .owl-dot.active span {
    opacity: 1;
}

.functional-testing-box ul.list-view li:hover {
    background-color: #0a5ca3;
    transition: 0.5s;
}

.functional-testing-box ul.list-view li:hover h4, 
.functional-testing-box ul.list-view li:hover p {
    color: #fff;
}

.functional-testing-box ul.list-view li:hover img {
    filter: invert(92%) sepia(20%) saturate(0%) hue-rotate(
    280deg
    ) brightness(104%) contrast(400%);
}

.case-studies h4 {
    text-align: center;
    font-size: 25px;
    margin-bottom: 40px;
}


.tabbox::-webkit-scrollbar {
  height: 5px;
}

.tabbox::-moz-scrollbar,
.tabbox::-ms-scrollbar {
  height: 5px;
}




.accordionbox-content .accordionbox {
    border: 0px;
}

.accordionbox-content .accordionbox .card-body {
    border: 0px;
    padding-top: 0px;
}

.accordionbox-content .accordionbox .card-body p {
    margin: 0px;
}

.accordionbox .accordion.collapsed {
    border: 1px solid #DFDFDF;
    background-color: #fff;
    border-radius: 6px;
    font-weight: 400;
}

.accordionbox .collapse,
.accordionbox .collapsing {
    background-color: #F2F5F6;
}

.designProcessTab .tab-content .accordionbox .collapse {
    display: none !important;
}

.designProcessTab .tab-content .accordionbox .collapse.show {
    display: block !important;
    border-radius: 0px 0px 6px 6px;
}

.carouselbox .owl-thumbs {
    display: none;
}

.customList h4 {
    margin: 25px 0px;
}

.functional-testing-box h4 {
    margin: 0px 0px 15px;
}

section.case-studies {
    padding-top: 25px;
}

.designProcessTab .tab-content .mobTab .nav-link::after {
    content: '+';
    position: absolute;
    right: 10px;
    font-size: 30px;
    top: -4px;
    color: #374e62;
}

.designProcessTab .tab-content .mobTab a.nav-link[aria-expanded=true]::before {
    content: '-';
    color: #fff;
    position: absolute;
    top: 0;
    right: 14px;
    font-size: 27px;
}

.designProcessTab .tab-content .mobTab .nav-link {
    position: relative;
}

.designProcessTab .tab-content .mobTab__header a[aria-expanded=true]::after{
    display: none;
}







@media only screen and (max-width: 1180px){
    .process-flowbox .process-flowbox_detail {
        padding: 30px 20px !important;
    }
    .functional-testing-box {
        padding: 30px 20px 20px;
        margin: 50px 0px;
    }
    .functional-testing-box ul.list-view li {
        width: 30.33%;
        padding: 20px 15px 20px;
    }
    .designProcessTab ul#tabs li a h4 {
        font-size: 18px;
    }
    .designProcessTab ul#tabs li a {
        padding: 15px 10px;
    }
}

@media only screen and (max-width: 991px){
    .processFlow .processflow-img h4 {
        padding-top: 30px;
        margin-bottom: 30px;
    }
    .designProcessTab .headingCaption h3 {
        font-size: 30px;
        margin-bottom: 25px;
    }
    
    .designProcessTab ul#tabs li a {
        padding: 10px;
    }
    .designProcessTab ul#tabs li a h4 {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px){
    .functional-testing-box ul.list-view li {
        width: 45%;
        margin: 0 10px 20px;
    }
    .process-flowbox h4 {
        margin-bottom: 30px;
    }
    .designProcessTab ul#tabs {
        display: none;
    }
    .carouselbox {
        margin: 20px 0px;
    }
    .section-space {
        margin: 2rem 0;
    }
    .customList h2 {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .carouselbox .owl-carousel .owl-dots {
        margin-top: 20px !important;
        text-align: center;
    }
    .carouselbox h4 {
        margin-bottom: 20px;
    }
    .tabbox {
        margin-bottom: 0px;
    }
    .customList h4 {
        margin: 20px 0px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 580px){
    .process-flowbox .process-flowbox_detail {
        padding: 15px 20px !important;
    }
    .processFlow .processflow-img h4 {
        padding-top: 15px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 480px){
    .functional-testing-box ul.list-view li {
        width: 100%;
        margin: 0 0px 20px;
        text-align: center;
    }
    .functional-testing-box ul.list-view li h4,
    .functional-testing-box ul.list-view li p {
        text-align: center;
    }
    .processFlow .processflow-img h4 {
        padding-top: 10px;
        font-size: 16px;
    }
    .accordionbox .accordion {
        font-size: 14px;
        padding-right: 50px;
    }
    .case-studies ul.case-studies-content li p {
        font-size: 14px;
    }
    .case-studies ul.case-studies-content li h5 {
        font-size: 16px;
    }
    .case-studies h4 {
        margin-bottom: 18px;
    }
    .designProcessTab .headingCaption h3 {
        font-size: 30px;
        margin: 0px 0px 15px;
    }
    .process-flowbox h4 {
        font-size: 16px;
    }
    .process-flowbox h4 {
        margin-bottom: 15px;
    }
}
