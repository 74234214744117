// just keep icons here as it will be updated with future additions

/*------------------------
  font icons
-------------------------*/

.nc-icon.nc-award-1:before {
  content: "\ea03";
}

.nc-icon.nc-button-circle-play-1:before {
  content: "\ea04";
}

.nc-icon.nc-circle-add-1:before {
  content: "\ea05";
}

.nc-icon.nc-circle-delete-1:before {
  content: "\ea06";
}

.nc-icon.nc-circle-down-40-1:before {
  content: "\ea07";
}

.nc-icon.nc-circle-left-38-1:before {
  content: "\ea08";
}

.nc-icon.nc-circle-remove-1:before {
  content: "\ea09";
}

.nc-icon.nc-circle-right-37-1:before {
  content: "\ea0a";
}

.nc-icon.nc-circle-up-39-1:before {
  content: "\ea0b";
}

.nc-icon.nc-favourite-31-1:before {
  content: "\ea0c";
}

.nc-icon.nc-heart-2-1:before {
  content: "\ea0d";
}

.nc-icon.nc-logo-facebook:before {
  content: "\ea0e";
}

.nc-icon.nc-logo-instagram:before {
  content: "\ea0f";
}

.nc-icon.nc-logo-pinterest:before {
  content: "\ea10";
}

.nc-icon.nc-logo-twitter:before {
  content: "\ea11";
}

.nc-icon.nc-pin-3-1:before {
  content: "\ea12";
}

.nc-icon.nc-award-2:before {
  content: "\ea13";
}

.nc-icon.nc-chat-round-content:before {
  content: "\ea15";
}

.nc-icon.nc-circle-add-2:before {
  content: "\ea16";
}

.nc-icon.nc-circle-delete-2:before {
  content: "\ea17";
}

.nc-icon.nc-circle-down-40-2:before {
  content: "\ea18";
}

.nc-icon.nc-circle-left-38-2:before {
  content: "\ea19";
}

.nc-icon.nc-circle-remove-2:before {
  content: "\ea1a";
}

.nc-icon.nc-circle-right-37-2:before {
  content: "\ea1b";
}

.nc-icon.nc-circle-up-39-2:before {
  content: "\ea1c";
}

.nc-icon.nc-email-85:before {
  content: "\ea1d";
}

.nc-icon.nc-favourite-31-2:before {
  content: "\ea1e";
}

.nc-icon.nc-heart-2-2:before {
  content: "\ea1f";
}

.nc-icon.nc-like-no:before {
  content: "\ea20";
}

.nc-icon.nc-like:before {
  content: "\ea21";
}

.nc-icon.nc-minimal-down:before {
  content: "\ea22";
}

.nc-icon.nc-minimal-left:before {
  content: "\ea23";
}

.nc-icon.nc-minimal-right:before {
  content: "\ea24";
}

.nc-icon.nc-minimal-up:before {
  content: "\ea25";
}

.nc-icon.nc-mobile-button:before {
  content: "\ea26";
}

.nc-icon.nc-phone-2:before {
  content: "\ea27";
}

.nc-icon.nc-phone:before {
  content: "\ea28";
}

.nc-icon.nc-pin-3-2:before {
  content: "\ea29";
}

.nc-icon.nc-simple-add:before {
  content: "\ea2a";
}

.nc-icon.nc-simple-delete:before {
  content: "\ea2b";
}

.nc-icon.nc-simple-remove:before {
  content: "\ea2c";
}

.nc-icon.nc-single-copy-04:before {
  content: "\ea2d";
}

.nc-icon.nc-small-add:before {
  content: "\ea2e";
}

.nc-icon.nc-small-delete:before {
  content: "\ea2f";
}

.nc-icon.nc-small-down:before {
  content: "\ea30";
}

.nc-icon.nc-small-left:before {
  content: "\ea31";
}

.nc-icon.nc-small-remove:before {
  content: "\ea32";
}

.nc-icon.nc-small-right:before {
  content: "\ea33";
}

.nc-icon.nc-small-up:before {
  content: "\ea34";
}

.nc-icon.nc-square-download:before {
  content: "\ea35";
}

.nc-icon.nc-stre-down:before {
  content: "\ea36";
}

.nc-icon.nc-stre-left:before {
  content: "\ea37";
}

.nc-icon.nc-stre-right:before {
  content: "\ea38";
}

.nc-icon.nc-stre-up:before {
  content: "\ea39";
}

.nc-icon.nc-tail-down:before {
  content: "\ea3a";
}

.nc-icon.nc-tail-left:before {
  content: "\ea3b";
}

.nc-icon.nc-tail-right:before {
  content: "\ea3c";
}

.nc-icon.nc-tail-up:before {
  content: "\ea3d";
}

.nc-icon.nc-time-3:before {
  content: "\ea3e";
}

.nc-icon.nc-zoom-2:before {
  content: "\ea3f";
}

.nc-icon.nc-ic_info_outline_24px:before {
  content: "\ea40";
}

.nc-icon.nc-circle-right-filled-09:before {
  content: "\ea42";
}

.nc-icon.nc-moon-stars:before {
  content: "\ea45";
}

.nc-icon.nc-cart-refresh:before {
  content: "\ea46";
}

.nc-icon.nc-bike-sport:before {
  content: "\ea47";
}

.nc-icon.nc-crosshair:before {
  content: "\ea48";
}

.nc-icon.nc-award-49:before {
  content: "\ea49";
}

.nc-icon.nc-bike-sport-2:before {
  content: "\ea4a";
}

.nc-icon.nc-snow:before {
  content: "\ea4b";
}