.box {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-height: 320px;

    @include media-breakpoint-up(sm) {}

    .image {
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            transform: scale(1.07, 1.07);
            transition-property: all;
            transition-duration: 0.4s;
        }
    }

    .content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        line-height: 1.5;
        display: flex;
        align-items: flex-end;
        padding: 15px 25px;
        color: white;
        font-size: 15px;
        letter-spacing: 1px;
        background: rgba(0, 0, 0, 0.57);
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
        z-index: 2;

        h2 {
            font-family: Roboto, sans-serif;
            font-size: rem(20px);
            color: #ffffff;

            @include media-breakpoint-up(sm) {
                font-size: rem(24px);
            }
        }

        a {
            color: #ffffff;
            font-size: 12px;
        }
    }

    &:hover img {
        transform: scale(1, 1);
        background-position: top top;
        filter: grayscale(0.4) blur(2px);
        transition-property: all;
        transition-duration: 0.8s;
        filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    }
}

.greenBox {
    background: #129475;

    .content {
        background: transparent;
    }
}

.contentBlock {
    max-width: 460px;
}


#designStudioTabContent .row {
    margin: 0 -0.5rem;
}

.headingBox {
    padding: 40px;
}

.headingCaption {
    font-size: 15px;
    color: #CCCCCC;
    font-style: italic;
    letter-spacing: 1px;
    margin-bottom: 15px;
    font-family: Roboto, sans-serif;
}

.studioHeading {
    letter-spacing: 3px;
    color: #000000;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 25px;
    font-family: Roboto, sans-serif;
    position: relative;

    @include media-breakpoint-up(sm) {
        font-size: 62px;
    }

    @media only screen and (max-width: $smd) {
        br {
            display: none;
        }
    }

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        height: 2px;
        background: #22B995;
    }
}

.box-shadow {
    box-shadow: 0px 3px 19px #00000026;
}

.tab-designStudio {
    font-family: Roboto, sans-serif;

    .nav-tabs {
        border-bottom: none;
        margin-bottom: 8px;

        .nav-link {
            border: none;
            color: #AAAAAA;
            font-size: rem(12px);
            border-radius: 30px;
            padding: rem(10px 15px);
            min-width: 100px;
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            position: relative;
            transition: all .3s ease-in-out;

            @include media-breakpoint-up(sm) {
                padding: 10px 30px;
                font-size: 16px;
                min-width: 150px;
                letter-spacing: 1px;
                border-radius: 50px;
            }

            &:hover{
                text-decoration: none
            }

            &.active {
                color: #0A9AB2;
                box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.19);

                &:after {
                    content: "";
                    position: absolute;
                    width: 12px;
                    height: 19px;
                    background: #ffffff;
                    transform: rotate(45deg);
                    bottom: -7px;
                    right: 25px;

                    @include media-breakpoint-up(sm) {
                        right: 40px;
                    }
                }
            }
        }
    }
}

.about-designStudio {
    font-family: Roboto, sans-serif;
    font-size: 15px;
    line-height: 1.9;

    h3 {
        font-family: Roboto, sans-serif;
        font-size: 17px;
        line-height: 1.7;
        font-weight: 600;
        font-style: italic;
        margin-bottom: 15px;
    }

    .headingCaption {
        color: #4A4A4A;
    }

    .studioHeading {
        line-height: 0.9;
        font-size: rem(32px);

        @include media-breakpoint-up(sm) {
            font-size: rem(55px);
        }
    }
}

.aboutBlock {
    margin-top: 25px;

    h2 {
        font-family: Roboto, sans-serif;
        font-size: 25px;
        font-weight: 700;
        margin: 20px 0;
    }

    &__icon {
        margin: 20px 0;
        min-height: rem(52px)
    }
}


.portfolioSection--studio {
    position: relative;
    font-family: Roboto, sans-serif;
    overflow-x: hidden;

    @include media-breakpoint-up(md) {
        overflow-x: visible;
    }

    &:before {
        content: "";
        background: #FBF9F9;
        width: 1000%;
        height: 100%;
        position: absolute;
        top: 0;
        left: -50%;
        pointer-events: none;
        z-index: 0;
    }

    .seeMore {
        z-index: 2;
        position: relative;
    }

    .section-title-large {
        font-family: Roboto, sans-serif;
        font-size: rem(40px);
        letter-spacing: 2px;
        font-weight: 700;
        position: relative;
        padding-bottom: 25px;
        margin-bottom: 25px;

        @include media-breakpoint-up(sm) {
            font-size: rem(50px);
        }

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 125px;
            height: 3px;
            background: #22B995;
            margin: 0 auto;
        }
    }

    .headingCaption {
        color: #4A4A4A;
    }

    .portfolio-scroll {
        @include media-breakpoint-down(sm) {
            width: 100%;
            overflow-x: auto;
        }

        .portfolio-item-list {
            margin: 30px auto 0;

            @include media-breakpoint-down(sm) {
                width: 1800px;
            }

            @include media-breakpoint-up(md) {
                margin: 30px -19px 0;
            }

            .portfolio-item {
                padding: 0 19px;

                @include media-breakpoint-down(md) {
                    max-width: 300px;
                }

                h2 a {
                    font-family: Roboto, sans-serif;
                    font-size: rem(18px);
                    letter-spacing: 1px;
                    font-weight: 700;
                    margin-top: 0px;
                    text-align: center;
                    pointer-events: none;

                    @include media-breakpoint-up(sm) {
                        font-size: rem(25px);
                        margin-top: 20px;
                        text-align: left;
                    }

                    &::after {
                        display: none;

                        @include media-breakpoint-up(sm) {
                            display: block;
                        }
                    }
                }

                .portfolio-meta {
                    text-align: center;

                    a {
                        pointer-events: none;
                    }

                    @include media-breakpoint-up(sm) {
                        text-align: left;
                    }
                }

                a {
                    pointer-events: none;
                }
            }
        }
    }

    .btn--black.show_more {
        background: #129475;
        border-color: #129475;
    }
}

@media (min-width: 992px) {
    .portfolioSection--studio .portfolio-item-list .portfolio-item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}


.clienteleSection .studioHeading {
    font-size: 30px;
    text-transform: uppercase;
    text-align: left;

    @include media-breakpoint-up(sm) {
        font-size: rem(36px);
    }

    &:after {
        margin: 0 auto;
        right: 0;
        width: 40%;
    }
}

.studioHero {
    font-family: Roboto, sans-serif;
}

.designProcessTab {

    .studioHeading {
        padding-bottom: rem(10px);
        font-size: rem(32px);

        @include media-breakpoint-up(sm) {
            font-size: rem(50px);
        }

        &:after {
            display: none;
        }
    }

    .headingCaption {
        margin-bottom: rem(2px);
        color: #999999;
        font-weight: normal;
    }

    .nav-tabs {
        display: none;
        border-bottom: none;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            padding: 0;
            max-width: rem(260px);
        }

        @include media-breakpoint-up(xl) {
            max-width: rem(400px)
        }
    }

    .nav-link {
        border-radius: 0;
        padding: rem(15px);
        margin-bottom: rem(2px);
        transition: all ease-in-out .5s;
        border-top: 1px solid #b9b7b7;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            padding: rem(10px);
        }

        @include media-breakpoint-up(lg) {
            padding: rem(20px 15px);
        }

        @include media-breakpoint-up(xl) {
            padding: rem(24px 21px);
        }

        .dpTitlle {
            font-size: rem(18px);
            color: #374E62;
            margin: 0;
        }

        &:hover {
            @include media-breakpoint-up(md) {
                color: $white;
                border-color: transparent;
                text-decoration: none;
                background: #374E62;

                .dpTitlle {
                    color: $white;
                }
            }
        }

        &.active {
            color: $white;
            border-color: transparent;
            background: #374E62;

            .dpTitlle {
                color: $white;
            }
        }
    }

    .nav-item:first-child .nav-link {
        border-top: none;
    }


    .tab-content {
        color: #BFBFBF;
        padding: 0;

        .mobTab {

            &__header {

                @include media-breakpoint-up(md) {
                    display: none;
                }

                a[aria-expanded="true"] {
                    color: $white;
                    border-color: transparent;
                    background: #374E62;

                    .dpIcon {
                        background-color: #ffffff;
                    }

                    .dpTitlle {
                        color: $white;
                    }
                }

            }

            &__body{
                padding: rem(0)
            }

            .collapse {
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }

            &:first-child .nav-link {
                border-top: none;
            }
        }
    }

    @media (max-width: 767px) {
        .tab-content>.tab-pane {
            display: block !important;
            opacity: 1;
        }
    }

    .overlay-content-box {
        height: 100%;

        .overlay-content-image {
            height: 100%;

            position: relative;

            >img {
                width: 100%;
            }

            .overlay-content {
                padding: rem(15px);
                font-size: rem(13px);
                background-color: $white;
                color: #333333;
                margin: 0;
                font-family: $sans-serif-font;
                line-height: 1.4;

                @media only screen and (min-width: 768px) {
                    padding: rem(0 30px 20px);
                    font-size: 15px;
                    color: $white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: transparent;
                    background-image: linear-gradient(to top, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 10%, rgba(0, 0, 0, 0) 100%);
                }

                h3 {
                    font-size: rem(20px);
                    color: $black;
                    font-weight: 400;
                    margin-bottom: ren(10px);
                    font-family: $sans-serif-font;

                    @media only screen and (min-width: 768px) {
                        font-size: rem(24px);
                        color: $white;
                    }
                }
            }
        }
    }

    &.softwearTestingTab {
        .nav-tabs {
            max-width: rem(320px);
        }

        .tab-content {
            color: #0A0A0A;
            font-size: rem(14px);

            @include media-breakpoint-up(md) {
                padding-left: rem(50px);
            }
        }
    }
}

.headingdots {
    background: #0A98B1;
    width: rem(10px);
    height: rem(10px);
    border-radius: rem(10px);
    display: inline-block;
    margin-left: rem(10px);
}

.portfolioSection--video 
.portfolio-item-list {
.portfolio-item {
    max-width: 100%;

    &--video {
        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        .portfolio-scroll .portfolio-item-list{
            @include media-breakpoint-up(md) {
                width: auto;
            }
        }
    }
}
}

.processFlow {
    background: #F3F3F3;
    padding: rem(50px 15px) !important;

    &__block {
        max-width: 1070px;
        margin: 0 auto;
        width: 100%;
        overflow-x: auto;
            &>.row{
                min-width: 800px
        }
    }
    &__image{
        min-width: 160px;
    }   
}

.hostingContactBtn {
    margin: 30px auto;
    display: block;
    max-width: 360px;

    @include media-breakpoint-up(down) {
        color: $white;
        font-size: 14px;
        letter-spacing: 1px;
        padding-left: rem(15px);
        padding-right: rem(15px);
    }
}

.portfolio-item--overlay {
    margin: rem(0 -5px);

    .portfolio-item {
        padding: rem(0 5px);
        margin-bottom: rem(10px);

        .portfolio-thumb {
            position: relative;
            overflow: hidden;

            img {
                transition: opacity 1s, transform 1s;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 11;
                transition: opacity 1s, transform 1s;
                background: transparent linear-gradient(180deg, #00000000 0%, #000000D9 100%) 0% 0% no-repeat padding-box;
            }

        }

        .project-title {
            position: absolute;
            bottom: 5px;
            left: 25px;
            z-index: 2;
            text-transform: uppercase;

            a {
                color: $white;
                font-size: rem(15px);
                transition: opacity 1s, transform 1s;

                &:after {
                    display: none;
                }
            }
        }

        &:hover {
            .portfolio-thumb {
                img {
                    transform: scale3d(1.2, 1.2, 1.1);
                }

                &:after {
                    opacity: .5
                }
            }

            .project-title a {
                transform: scale3d(1.1, 1.1, 1);
            }
        }
    }
}

.sidebarSticky{
    max-width: rem(320px);
    display: none;
    @include media-breakpoint-up(md) {
        display: block;
    }
}
.customList a{
    color: #0a0a0a;
    transition: all .2s ease-in-out
}
.customList a:hover{
    text-decoration: underline;
    color: #000000;
}

.mark, mark {
    padding: .2em;
    background-color: #FFEB3B;
}   

.portfolioSection--studio.portfolioSection--video .portfolio-scroll .portfolio-item-list {
    width: auto;
}