.success-section{
    padding: 100px 0 50px 0;
    font-size: 16px;

    @media only screen and (min-width: $sm) {
        padding: 100px 0 100px 0;
        font-size: 22px;
    }

    .gradient-heading{
        margin-bottom: 30px;
        font-size: 56px;
        font-family: $serif-font;
        font-weight: $font-weight-bold;
        font-style: italic;

        @media only screen and (min-width: $sm) {
            margin-bottom: 50px;
        font-size: 90px;
        }
    }

    p{
        margin-bottom: 0
    }

}
