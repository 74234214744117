/*
 * HS Mega Menu - jQuery Plugin
 * @version: 1.0.0 (Sun, 26 Feb 2017)
 *
 * @license:
 *
 * Copyright 2017 HtmlStream
 *
 */

.hs-menu-initialized {
    position: relative;
    z-index: 10;
}

.hs-menu-initialized .animated {
    -webkit-animation-duration: 300ms;
    animation-duration: 300ms;
}

.hs-overflow-x-locked {
    overflow-x: hidden;
}

.hs-sub-menu,
.hs-mega-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 2;
    margin-top: -.002rem;
    visibility: hidden;
    background-color: #fff;
    opacity: 1 !important;
}

.hs-sub-menu-opened > .hs-sub-menu,
.hs-mega-menu-opened > .hs-mega-menu {
    visibility: visible;
    opacity: 1;
}

.hs-sub-menu {
    min-width: 180px;
}

.hs-has-sub-menu {
    overflow: hidden;
    position: relative;
}

.hs-sub-menu-opened {
    overflow: visible;
}

.hs-sub-menu .hs-sub-menu,
.hs-mega-menu .hs-sub-menu,
.hs-sub-menu .hs-mega-menu,
.hs-mega-menu .hs-mega-menu {
    top: 0;
    left: 100%;
    margin-top: 0;
}

/*------------------------------------
    Vertical Direction
------------------------------------*/

.hs-menu-vertical .hs-sub-menu,
.hs-menu-vertical .hs-mega-menu {
    top: 0;
    left: 100%;
    margin-top: 0;
}

.hs-menu-vertical .hs-sub-menu {
    width: auto;
}

.hs-menu-vertical .hs-mega-menu {
    height: 100%;
}

/*------------------------------------
    Mobile state
------------------------------------*/

.hs-mobile-state .hs-sub-menu,
.hs-mobile-state .hs-mega-menu {
    position: static;
    visibility: visible;
}

.hs-mobile-state .hs-has-mega-menu[data-max-width] > .hs-mega-menu,
.hs-mobile-state .hs-has-sub-menu[data-max-width] > .hs-sub-menu {
    max-width: initial !important;
}

/*------------------------------------
    RTL
------------------------------------*/

.hs-menu-initialized.hs-rtl {
    direction: rtl;
    unicode-bidi: embed;
}

.hs-menu-initialized.hs-rtl .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu {
    left: auto;
    right: 0;
}

.hs-menu-initialized.hs-rtl .hs-sub-menu .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu .hs-sub-menu,
.hs-menu-initialized.hs-rtl .hs-sub-menu .hs-mega-menu,
.hs-menu-initialized.hs-rtl .hs-mega-menu .hs-mega-menu {
    left: auto;
    right: 100%;
}

/*------------------------------------
    Smart Position
------------------------------------*/

.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu.hs-reversed {
    left: auto;
    right: 0;
}

.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-sub-menu .hs-mega-menu.hs-reversed,
.hs-menu-initialized:not(.hs-mobile-state) .hs-mega-menu .hs-mega-menu.hs-reversed {
    left: auto;
    right: 100%;
}

.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu.hs-reversed {
    right: auto;
    left: 0;
}

.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu .hs-sub-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-sub-menu .hs-mega-menu.hs-reversed,
.hs-menu-initialized.hs-rtl:not(.hs-mobile-state) .hs-mega-menu .hs-mega-menu.hs-reversed {
    right: auto;
    left: 100%;
}

/*------------------------------------
    Positions
    (only 'horizontal' direction)
------------------------------------*/

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-left {
    left: 0;
    right: auto;
}

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-right {
    left: auto;
    right: 0;
}

.hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-center {
    right: auto;
    left: 50%;

    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
