/*------------------------------------
  Hamburgers
------------------------------------*/
.u-hamburger {
    font-size: inherit;
    line-height: 0;
    padding: 0.25rem;
}

.u-hamburger:hover .u-hamburger__inner,
.u-hamburger:hover .u-hamburger__inner::before,
.u-hamburger:hover .u-hamburger__inner::after {
    background-color: #330ba9;
}

.u-hamburger__box {
    width: 1.5625rem;
    height: 0.875rem;
    display: inline-block;
    position: relative;
}

.u-hamburger__inner {
    display: block;
    top: 0.0625rem;
    margin: top, -0.0625rem;
}

.u-hamburger__inner,
.u-hamburger__inner::before,
.u-hamburger__inner::after {
    width: 1.5625rem;
    height: 0.125rem;
    background-color: #8c98a4;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease;
}

.u-hamburger__inner::before,
.u-hamburger__inner::after {
    content: "";
    display: block;
}

.u-hamburger__inner::before {
    top: 0.375rem;
    transition-property: transform, opacity;
    transition-duration: 0.3s;
    transition-timing-function: ease;
}

.u-hamburger__inner::after {
    top: 0.75rem;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner,
.u-hamburger .is-active .u-hamburger__inner {
    transform: translate3d(0, 0.375rem, 0) rotate(45deg);
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::before,
.u-hamburger .is-active .u-hamburger__inner::before {
    transform: rotate(-45deg) translate3d(-0.22321rem, -0.25rem, 0);
    opacity: 0;
}

.u-hamburger[aria-expanded="true"] .u-hamburger__inner::after,
.u-hamburger .is-active .u-hamburger__inner::after {
    transform: translate3d(0, -0.75rem, 0) rotate(-90deg);
}

.u-hamburger--white .u-hamburger__inner,
.u-hamburger--white .u-hamburger__inner::before,
.u-hamburger--white .u-hamburger__inner::after {
    background-color: rgba(255, 255, 255, 0.8);
}

.u-hamburger--white:hover .u-hamburger__inner,
.u-hamburger--white:hover .u-hamburger__inner::before,
.u-hamburger--white:hover .u-hamburger__inner::after {
    background-color: #fff;
}

.js-header-fix-moment .u-hamburger--white .u-hamburger__inner,
.js-header-fix-moment .u-hamburger--white .u-hamburger__inner::before,
.js-header-fix-moment .u-hamburger--white .u-hamburger__inner::after {
    background-color: #8c98a4;
}

.js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner,
.js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::before,
.js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner::after {
    background-color: #330ba9;
}

/*------------------------------------
  Header Default
------------------------------------*/
.u-header {
    position: relative;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    background: $white;

    .nav-link{
        padding-top: rem(5px);
        padding-bottom:  rem(5px);
    }
}

.u-header__section {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding: 0px;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.3);
    padding-bottom: rem(5px);

    @include media-breakpoint-up(lg) {
        padding: rem(0 35px);
    }
    &>.container{
        padding: rem(0 15px);
    }
}

.mobileContact {
    text-align: center;
    margin-top: 12px;
}

/* Navbar */
.u-header__navbar {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0;
    padding-right: 0;
}

/* Navbar Space */
@media (max-width: 1024.98px) {
    .u-header .u-header__navbar.u-header__navbar--top-space {
        padding-top: 0.25rem;
    }
}

/* Navbar Brand */
.u-header__navbar-brand,
.u-header__navbar-brand>img {
    padding-top: 0;
    padding-bottom: 0;
}

.u-header__navbar-brand-center {
    display: flex;
    align-items: center;
}

/* Navbar Link */
.u-header__navbar-link {
    color: #010101;
    font-size: 0.875rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

/* Nav Item */
.u-header .u-header__nav-item:first-child .u-header__nav-link {
    padding-left: 0;
}

.u-header__nav-item:hover .u-header__nav-link,
.u-header__nav-item:focus .u-header__nav-link {
    color: #330ba9;
}

/* Nav Link */
.u-header .active>.u-header__nav-link {
    color: #330ba9;
}

.u-header__nav-link {
    color: #010101;
}

.u-header__nav-link-toggle {
    position: relative;
}

.u-header__nav-link-toggle::after {
    display: inline-block;
    font-family: 'Font Awesome 5 Free';
    font-size: 65%;
    font-weight: 900;
    content: "\f107";
    margin-left: .5rem;
    transition: 0.3s ease;
}


/* Sub Menu */
.u-header__sub-menu {
    width: 100%;
    padding-left: 0;
    list-style: none;
}

.u-header__sub-menu-nav-group {
    padding-left: 0;
    list-style: none;
}

.u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.875rem;
    color: #585858;
}

.u-header__sub-menu-nav-link-toggle {
    position: relative;
}

.u-header__sub-menu-nav-link-toggle::after {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-size: 80%;
    font-weight: 900;
    content: "\f105";
    margin-left: auto;
    margin-right: .04rem;
    transition: 0.3s ease;
    color: #c1c0c0
}

.u-header__sub-menu .active>.u-header__sub-menu-nav-link {
    color: #330ba9;
}

/*------------------------------------
  Nav Alignments
------------------------------------*/
@media (min-width: 992px) {

    .u-header__navbar-body,
    .u-header__navbar-nav {
        margin-left: auto;
    }
}

/*------------------------------------
  Header Navbar Background Colors
------------------------------------*/
.u-header--navbar-bg .u-header__navbar-brand-text {
    color: #fff;
}

.u-header--navbar-bg .u-header__nav-item:hover .u-header__nav-link {
    color: #fff;
}

.u-header--navbar-bg .active>.u-header__nav-link {
    color: #fff;
}

.u-header--navbar-bg .u-header__nav-link {
    color: rgba(255, 255, 255, 0.7);
}

.u-header--navbar-bg .u-header__nav-link:hover {
    color: #fff;
}

.u-header--navbar-bg .u-header__navbar-link,
.u-header--navbar-bg .dropdown-nav-link,
.u-header--navbar-bg .btn-text-secondary {
    color: rgba(255, 255, 255, 0.6);
}

.u-header--navbar-bg .u-header__navbar-link:hover,
.u-header--navbar-bg .dropdown-nav-link:hover,
.u-header--navbar-bg .btn-text-secondary:hover {
    color: #fff;
}

.u-header--navbar-bg .btn-text-secondary[href]:hover {
    color: #fff;
}

.u-header--navbar-bg .u-hamburger__inner,
.u-header--navbar-bg .u-hamburger__inner::before,
.u-header--navbar-bg .u-hamburger__inner::after {
    background-color: rgba(255, 255, 255, 0.5);
}

.u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner,
.u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::before,
.u-header--navbar-bg .u-hamburger:hover .u-hamburger__inner::after {
    background-color: #fff;
}

/*------------------------------------
  Header Helpers
------------------------------------*/
.u-header--invisible {
    display: none;
}

.u-header--moved-up {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}

.u-header--faded {
    opacity: 0;
    visibility: hidden;
}

.u-header__section--hidden {
    position: relative;
}

.u-header[data-header-fix-effect] {
    transition: 0.3s ease;
}

.u-header.u-header--untransitioned {
    transition: none;
}

.u-header.js-header-fix-moment {
    position: fixed;
    top: 0;
    bottom: auto;
}

.u-header--fix-top {
    position: fixed;
}

.u-header.u-header--fix-top[data-effect-compensation] {
    transition: none;
}

/*------------------------------------
  Transparent Header
------------------------------------*/
.u-header--bg-transparent:not(.js-header-fix-moment) .u-search-push-top[style*="display: block"]+.u-header__section {
    background-color: #fff;
}

.u-header--bg-transparent:not(.js-header-fix-moment) .u-header__section {
    background-color: transparent;
    box-shadow: none;
}

.u-header--bg-transparent .u-header__navbar-brand-collapsed,
.u-header--bg-transparent .u-header__navbar-brand-collapsed .u-header__navbar-brand-text,
.u-header--bg-transparent .u-header__navbar-brand-on-scroll,
.u-header--bg-transparent .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: none;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-default,
.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-default .u-header__navbar-brand-text {
    display: none;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-on-scroll {
    display: -ms-flexbox;
    display: flex;
}

.u-header--bg-transparent.js-header-fix-moment .u-header__navbar-brand-on-scroll .u-header__navbar-brand-text {
    display: inline-block;
}

.u-header--bg-transparent .u-header__navbar-brand-default,
.u-header--bg-transparent .u-header__navbar-brand-on-scroll {
    display: none;
}

.u-header--bg-transparent .u-header__navbar-brand-default {
    display: -ms-flexbox;
    display: flex;
}

@media (max-width: 767.98px) {
    .u-header--bg-transparent .u-header__navbar-nav {
        background-color: #fff;
    }

    .u-header--bg-transparent .u-header__navbar-nav {
        padding: rem(12px 0);
    }
}

/*------------------------------------
  Header On Scroll
------------------------------------*/
.u-header--white-bg-on-scroll.u-scrolled .u-header__section {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, 0.3);
}

/*------------------------------------
  Navbar Expand
------------------------------------*/
.navbar-expand {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.navbar-expand.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-expand .u-header__navbar-nav {
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0.1875rem solid #330ba9;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
    box-shadow: 0 8px 20px rgba(55, 125, 255, 0.075);
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: rem(15px);
    padding-left: rem(15px);
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0;
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem;
}

.navbar-expand .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
}

.navbar-expand .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
}

.navbar-expand .u-header__promo {
    border-bottom-right-radius: 0.3125rem;
}

.navbar-expand .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
}

.navbar-expand .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
}

.navbar-expand .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative;
}

.navbar-expand .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: "";
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .navbar-expand-xl.u-header__navbar--no-space {
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-expand-xl .u-header__navbar-nav {
        -ms-flex-align: center;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__nav-last-item {
        text-align: right;
        padding-left: 1rem;
        padding-right: 0;
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__nav-link {
        padding-top: .5rem;
        padding-bottom: .5rem;
        padding-right: 0.875rem;
        padding-left: 0.875rem;
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu {
        border-top: 0.1875rem solid #330ba9;
        border-bottom-right-radius: 0.3125rem;
        border-bottom-left-radius: 0.3125rem;
        box-shadow: 0 8px 20px rgba(55, 125, 255, 0.075);
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-link {
        padding-right: rem(15px);
        padding-left: rem(15px);
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
        padding-left: 0;
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
        padding-top: rem(8px);
        padding-bottom: rem(8px);
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
        padding: 0;
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
        margin-top: rem(-11px);
    }

    .navbar-expand-xl .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
        right: 20%;
    }

    .navbar-expand-xl .u-header__mega-menu-wrapper {
        padding: 2.5rem;
        margin-left: 0;
        margin-right: 0;
    }

    .navbar-expand-xl .u-header__promo {
        border-bottom-right-radius: 0.3125rem;
    }

    .navbar-expand-xl .u-header__promo-card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .navbar-expand-xl .u-header__promo-card-deck {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .navbar-expand-xl .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
        position: relative;
    }

    .navbar-expand-xl .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link::after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -1.5rem;
        border-right: 1px solid #e7eaf3;
        height: 100%;
        content: "";
    }

    .u-header__navbar-brand-center {
        top: rem(34px);
        position: relative;
        z-index: 999;
    }
}


@media (max-width: 1199.98px) {

    .hs-mobile-state .navbar-collapse{
        max-height: 80vh;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch
    }

    #header{
        position: fixed;
        top: 0;
        bottom: auto;
    }

    .navbar-expand-xl .u-header__nav-link,
    .navbar-expand-xl .u-header__sub-menu-nav-link {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }

    .navbar-expand-xl .u-header__nav-link-toggle::after,
    .navbar-expand-xl .u-header__sub-menu-nav-link-toggle::after {
        margin-left: auto;
    }

    .navbar-expand-xl .u-header__sub-menu-nav-link-toggle::after {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .navbar-expand-xl .hs-mega-menu-opened .u-header__nav-link-toggle::after,
    .navbar-expand-xl .hs-sub-menu-opened .u-header__nav-link-toggle::after {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }

    .navbar-expand-xl .hs-sub-menu-opened>.u-header__sub-menu-nav-link-toggle::after {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .navbar-expand-xl .u-header__mega-menu-wrapper {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .navbar-expand-xl .u-header__sub-menu {
        min-width: 100% !important;
    }

    .navbar-expand-xl .u-header__sub-menu--spacer {
        padding-left: 1.5rem;
    }

    .navbar-expand-xl .u-header__sub-menu:not(.u-header__promo) {
        border-left: 0.1875rem solid #330ba9;
    }

    .navbar-expand-xl .u-header__sub-menu-title {
        padding-left: 1.5rem;
        margin-bottom: 0;
    }

    .navbar-expand-xl .u-header__sub-menu-nav-group {
        padding-top: .5rem;
        padding-left: 1.5rem;
    }

    .navbar-expand-xl .u-header__sub-menu {
        padding-left: 1.5rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
        animation: none;
    }

    .navbar-expand-xl .u-header-center-aligned-nav__col-last-item {
        display: none;
    }

    .navbar-expand-xl .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
        border-bottom: 1px solid #e7eaf3;
    }
}

@media (max-width: 1199.98px) {

    .navbar-expand-xl .u-header__nav-link,
    .navbar-expand-xl .u-header__sub-menu-nav-link {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
    }

    .navbar-expand-xl .u-header__nav-link-toggle::after,
    .navbar-expand-xl .u-header__sub-menu-nav-link-toggle::after {
        margin-left: auto;
    }

    .navbar-expand-xl .u-header__sub-menu-nav-link-toggle::after {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .navbar-expand-xl .hs-mega-menu-opened .u-header__nav-link-toggle::after,
    .navbar-expand-xl .hs-sub-menu-opened .u-header__nav-link-toggle::after {
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
    }

    .navbar-expand-xl .hs-sub-menu-opened>.u-header__sub-menu-nav-link-toggle::after {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }

    .navbar-expand-xl .u-header__mega-menu-wrapper {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .navbar-expand-xl .u-header__sub-menu {
        min-width: 100% !important;
    }

    .navbar-expand-xl .u-header__sub-menu--spacer {
        padding-left: 1.5rem;
    }

    .navbar-expand-xl .u-header__sub-menu:not(.u-header__promo) {
        border-left: 0.1875rem solid #330ba9;
    }

    .navbar-expand-xl .u-header__sub-menu .u-header__sub-menu {
        padding-left: 0;
    }

    .navbar-expand-xl .u-header__sub-menu-title {
        padding-left: 1.5rem;
        margin-bottom: 0;
    }

    .navbar-expand-xl .u-header__sub-menu-nav-group {
        padding-top: .5rem;
        padding-left: 1.5rem;
    }

    .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu li {
        padding-left: 1.5rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
        animation: none;
    }

    .navbar-expand-xl .u-header-center-aligned-nav__col-last-item {
        display: none;
    }

    .navbar-expand-xl .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
        border-bottom: 1px solid #e7eaf3;
    }
}

.navbar-expand .u-header__nav-last-item {
    display: none;
}

.navbar-expand .u-header__nav-link,
.navbar-expand .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-expand .u-header__nav-link-toggle::after,
.navbar-expand .u-header__sub-menu-nav-link-toggle::after {
    margin-left: auto;
}

.navbar-expand .u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.navbar-expand .hs-mega-menu-opened .u-header__nav-link-toggle::after,
.navbar-expand .hs-sub-menu-opened .u-header__nav-link-toggle::after {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.navbar-expand .hs-sub-menu-opened>.u-header__sub-menu-nav-link-toggle::after {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.navbar-expand .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.navbar-expand .u-header__sub-menu {
    min-width: 100% !important;
}

.navbar-expand .u-header__sub-menu--spacer {
    padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid #330ba9;
}

.navbar-expand .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0;
}

.navbar-expand .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    animation: none;
}

.navbar-expand .u-header-center-aligned-nav__col-last-item {
    display: none;
}

.navbar-expand .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3;
}

@media (max-width: 1024.98px) {
    .navbar-expand-sm .u-header__nav-last-item {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .navbar-expand-xl .u-header__navbar-brand {
        padding-top: .5rem;
    }

    .u-header .u-header__mega-menu-position-right-fix--md.hs-mega-menu.hs-position-right {
        right: 20%;
    }
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .navbar-expand-sm .u-header__navbar-brand {
        padding-top: .5rem;
    }
}

@media (min-width: 992px) {
    .navbar-expand .u-header__nav-last-item {
        display: inline-block;
    }

    [class*="navbar-expand"]:not(.navbar-expand-xl) {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-lg-collapse-block .navbar-collapse {
        display: block !important;
    }
}

.u-header--abs-top-md,
.u-header--abs-bottom-md,
.u-header--abs-top-2nd-screen-md {
    position: absolute;
}

.u-header--abs-top-md {
    top: 0;
    bottom: auto;
}

.u-header--abs-top-2nd-screen-md:not(.js-header-fix-moment) {
    top: 100%;
    bottom: auto;
}

.u-header--abs-top-2nd-screen-md[data-header-fix-effect] {
    transition: none;
}

.u-header--abs-bottom-md:not(.js-header-fix-moment) {
    top: auto;
    bottom: 0;
}

.u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu {
    top: auto;
    bottom: 100%;
}

.u-header--abs-bottom-md:not(.js-header-fix-moment) .u-header__sub-menu .u-header__sub-menu {
    top: 0;
    bottom: auto;
}

.u-header--abs-bottom-md:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 0 -8px 20px rgba(151, 164, 175, 0.1);
}

.u-header--abs-bottom-md:not(.js-header-fix-moment) .slideInUp {
    animation-name: absoluteSlideInUp;
}

/* Disable box-shadow on mobile device */
.u-header--abs-top-2nd-screen:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu,
.u-header--abs-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .u-header__navbar-nav .u-header__sub-menu {
    box-shadow: none;
}

@-webkit-keyframes absoluteSlideInUp {
    from {
        transform: translate3d(0, -10px, 0);
    }
}

@keyframes absoluteSlideInUp {
    from {
        -webkit-transform: translate3d(0, -10px, 0);
        transform: translate3d(0, -10px, 0);
    }
}



/* Disable box-shadow on mobile device */
.u-header--sticky-bottom:not(.js-header-fix-moment) [class*="navbar-expand"] .navbar-nav .u-header__sub-menu {
    box-shadow: none;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 25%, 0);
        transform: translate3d(0, 25%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 25%, 0);
        transform: translate3d(0, 25%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

.hs-sub-menu li {
    border-bottom: 1px solid #f5f5f5;

    &:last-child{
        border-bottom: none
    }
}

.u-header__nav-link-icon {
    position: absolute;
    right: 2px;
    z-index: 9;
    top: 7px;
    display: inline-block;
    @media (max-width: 1199.98px) {
        width: 33px;
        height: 33px;
        right: 0;
        z-index: 9;
        top: 1px;
        text-align: center;
        background: #f5f5f5;
        &:after{
            margin-top: 7px;
        }
    }
}

.s-has-sub-menu>a{
    @media (max-width: 1199.98px) {
        max-width: calc(100% - 42px);
    }
}

@media (min-width: 1199.99px) {
    .navbar-expand-xl .u-header__navbar-nav>li {
        margin-right: 10px;
    }
    .u-header__sub-menu-nav-link-toggle.u-header__nav-link-icon{
        top: 4px;
        right: 10px;
    }
}
