.ourqualities-section {
    padding: rem(40px 15px);
}

.quality-listing {
    list-style: none;
    position: relative;
    padding:0;

    &>li {
        padding: 0 3px;
        @include media-breakpoint-down(md) {
            max-width: 100%;
            min-width: 50%;
            padding: rem(3px 6px);
        }
        @include media-breakpoint-down(xs) {
            min-width: 100%;
        }
        &:before{
            display: none;
        }
        p, h3 {
            font-size: 26px;
            line-height: 36px;
            color: #ffffff;
            margin: 0;
            padding: 2rem;
            height: 100%;
        }

    }
}


.quality-listing>li:first-child p, .quality-listing>li:first-child h3 {
    background: #345f80;
}

.quality-listing>li:nth-of-type(2) p, .quality-listing>li:nth-of-type(2) h3 {
    background: #8da0af;
}

.quality-listing>li:nth-of-type(3) p, .quality-listing>li:nth-of-type(3) h3 {
    color: #eff7fe;
    background: #a3a3a3;
}

.quality-listing>li:last-child p, .quality-listing>li:last-child h3 {
    color: #eff7fe;
    background: #8da0af;
}

.mission-statement {
    background: #f0f0f0;
    padding: 1.6rem 3rem;
}

.why-choose {
    background-color: #444;
    padding: 50px 30px;
    height: 100%;
}
.why-choose .section-tag {
    color: #ffffff;
}
.why-choose-us {
    flex-direction: column;
}
.why-choose-us .owl-thumbs {
    order: 0;
}
.why-choose-us .owl-stage-outer {
    order: 1;
    padding: 3rem 0 0 0;
}

.why-choose-us button.owl-thumb-item {
    border: 3px solid transparent;
    font-family: 'Roboto', sans-serif;
    font-size: 1.7rem;
    font-weight: 500;
    color: #cecece;
    padding: 0;
    height: 44px;
    width: 44px;
    text-align: center;
    line-height: 36px;
    background-color: transparent;
    transition: all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
    @include media-breakpoint-down(xs) {
        font-size: 1.3rem;
        line-height: 32px;
        height: 32px;
        width: 32px;
    }
}

.why-choose-us button.owl-thumb-item.active {
    border: 3px solid #e6e6e6;
}

.why-choose-us .owl-item>div {
    font-size: 30px;
    line-height: 36px;
    color: #ffffff;
    padding-left: 60px;
    position: relative;
    min-height: 75px;

    @include media-breakpoint-down(xs) {
        font-size: 20px;
        line-height: 26px;
    }
}

.why-choose-us .owl-item>div:before {
    content: "";
    position: absolute;
    left: 0;
    top: 17px;
    width: 47px;
    height: 2px;
    background: #e6e6e6;
}

.whychooseus-section{
    .row{
        margin: 0;

        .col{
            padding: 0
        }
    }
}
