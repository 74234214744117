.cloudSupportTab {

    .studioHeading {
        padding-bottom: rem(10px);
        font-size: rem(32px);

        @include media-breakpoint-up(sm) {
            font-size: rem(50px);
        }

        &:after {
            display: none;
        }
    }

    .headingCaption {
        margin-bottom: rem(2px);
        color: #999999;
        font-weight: normal;
    }

    .nav-tabs {
        display: none;
        border-bottom: none;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: nowrap;
            padding: 0;
        }

    }

    .nav-link {
        border-radius: 0;
        padding: rem(15px 0);
        margin-bottom: rem(2px);
        transition: all ease-in-out .5s;
        display: flex;
        align-items: center;
        border-top: 1px solid #b9b7b7;

        @include media-breakpoint-up(md) {
            border-top: none;
            display: block;
            padding: rem(15px 35px);
        }

        .iconBlock {
            text-align: center;
            display: block;
            margin-bottom: 5px;
            height: rem(40px);
            width: rem(60px);

            @include media-breakpoint-up(sm) {
                height: rem(60px);
            }

            img {
                height: 40px;

                @include media-breakpoint-up(sm) {
                    height: auto;
                }
            }
        }

        .dpTitlle {
            font-size: rem(24px);
            color: #888888;
            margin: 0;
             @include media-breakpoint-up(md) {
                font-size: rem(18px);
             }
        }

        &:hover {

            .dpTitlle {
                color: #888888;
            }
        }

        &.active {
            color: #374E62;
            border-color: transparent;
            background: $white;
            box-shadow: 0px 0px 10px 0px #00000026;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;

            &:after {
                position: absolute;
                background: #ffffff;
                content: "";
                height: 21px;
                left: 0px;
                right: 0;
                bottom: -9px;
                z-index: 1;
                box-shadow: none;
            }

            .dpTitlle {
                color: #374E62;
            }
        }
    }


    .tab-content {
        color: #0A0A0A;
        font-size: rem(14px);
        padding: rem(20px 15px);

        @include media-breakpoint-up(md) {
            padding: rem(25px 35px);
        }

        .mobTab {
            

            &__header {

                @include media-breakpoint-up(md) {
                    display: none;
                }

                a[aria-expanded="true"] .dpIcon {
                    background-color: #ffffff;
                }

            }

            .collapse {
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }

            &:first-child .nav-link {
                border-top: none;
            }
        }
    }

    @media (max-width: 767px) {
        .tab-content>.tab-pane {
            display: block !important;
            opacity: 1;
        }
    }

    .overlay-content-box {
        height: 100%;

        .overlay-content-image {
            height: 100%;

            position: relative;

            >img {
                width: 100%;
            }

            .overlay-content {
                padding: rem(15px);
                font-size: rem(13px);
                background-color: $white;
                color: #333333;
                margin: 0;
                font-family: $sans-serif-font;
                line-height: 1.4;

                @media only screen and (min-width: 768px) {
                    padding: rem(0 30px 20px);
                    font-size: 15px;
                    color: $white;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: transparent;
                    background-image: linear-gradient(to top, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 10%, rgba(0, 0, 0, 0) 100%);
                }

                h3 {
                    font-size: rem(20px);
                    color: $black;
                    font-weight: 400;
                    margin-bottom: ren(10px);
                    font-family: $sans-serif-font;

                    @media only screen and (min-width: 768px) {
                        font-size: rem(24px);
                        color: $white;
                    }
                }
            }
        }
    }

}

.headingdots {
    background: #0A98B1;
    width: rem(10px);
    height: rem(10px);
    border-radius: rem(10px);
    display: inline-block;
    margin-left: rem(10px);
}

.providesList {
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 1 0 auto;
    flex-wrap: wrap;
    flex-direction: row;
    display: flex;

    .providesBlock {
        padding: rem(30px 5px);
        text-align: center;
        border: 1px solid #F2F2F2;
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-left: -1px;
        margin-top: -1px;
        transition: background 0.2s;

        @include media-breakpoint-up(md) {
            width: 20%;
        }

        @include media-breakpoint-up(lg) {
            padding: rem(50px 35px);
        }

        &__icon {
            height: 80px;
            width: 80px;
            border-radius: 100%;
            margin: 0 auto 15px;
            position: relative;
            background: #ffffff;
            transition: background 0.5s ease-in-out;

            &:after {
                position: absolute;
                pointer-events: none;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                content: '';
                box-sizing: content-box;
                top: -7px;
                left: -7px;
                padding: 7px;
                box-shadow: 0 0 0 2px #ffffff;
                transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
                transform: scale(0.8);
                opacity: 0;
            }

            &>img {
                max-width: 55px;
                max-height: 55px;
            }
        }

        &__title {
            color: #374E62;
            line-height: 1.5;
            transition: color 0.3s ease-in-out;
        }

        &:hover {
            background: linear-gradient(to bottom,#3d159f 0,#581b96 100%);

            .providesBlock__icon {

                &:after {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            .providesBlock__title {
                color: #ffffff;
            }
        }
    }
}