/*------------------------------------
  Banner Section
------------------------------------*/
.page-fullscreen-section {
    background-image: linear-gradient(72deg, #693680 0%, #00A3B7 40%, #30C388 100%);
    padding: rem(0 15px);
    position: relative;
    background-color: #8da0af;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: visible;

    @include media-breakpoint-up(lg) {
        padding: rem(0 30px);
    }

    @include media-breakpoint-up(xl) {
        border-radius: rem(8px);
    }

    .row {
        min-height: rem(200px);
        padding-bottom: rem(40px);
        padding-top: rem(20px);

        @include media-breakpoint-up(lg) {
            min-height: rem(400px);
            padding-bottom: rem(60px);
        }
    }

    .section-tag {
        width: 160px;
        color: #ffffff;
    }

    h3,
    h1 {
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
        line-height: 1.4;
        font-weight: 800;
        color: #ffffff;
        letter-spacing: 3px;
        margin: rem(0 0 15px);
        padding: 0;

        @include media-breakpoint-up(lg) {
            font-size: 44px;
            text-align: left;
            line-height: 1.3;
        }
    }

    .herobanner {
        &__content {
            color: $white;
        }
    }
}

.vectors-container {
    position: relative;
    width: 100%;
    max-width: rem(400px);
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        text-align: right;

        img {
            max-width: 100%;
            max-height: rem(280px);
        }
    }

}



/*------------------------------------
  Technologies Tab
------------------------------------*/
.tab-technologies {
    margin-top: rem(-55px);
    position: relative;
    padding: rem(0 15px);

    @include media-breakpoint-up(lg) {
        margin-top: rem(-70px);
        padding: rem(0 20px);
    }

    .nav-tabs {
        flex-wrap: nowrap;
        border: none;
        overflow-x: auto;
        padding: rem(10px 0);
        width: calc(100% + 15px);

        @include media-breakpoint-up(lg) {
            width: 100%;
            max-width: 100%;
        }

        li {
            padding: rem(5px);
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;

            &:last-child {
                padding-right: rem(15px);

                @include media-breakpoint-up(lg) {
                    padding-right: rem(5px);
                }
            }

            .nav-link {
                background: #FFFFFF;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
                border-radius: 6px;
                min-width: rem(100px);
                padding: rem(8px 5px);
                height: 100%;
                transition: box-shadow .3s ease-out, border .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
                transition-delay: .1s;
                transform: translateZ(0);
                border-bottom: 5px solid transparent;

                @include media-breakpoint-up(xxl) {
                    padding: rem(13px 2px);
                }

                .techIcon {
                    margin-bottom: rem(10px);
                    min-height: rem(50px);
                }

                .tabTittle {
                    font-size: 14px;
                    font-family: $serif-font;
                    margin: 0;
                    color: #999999;
                    font-weight: $font-weight-bold;
                    transition: color 0.35s;
                }

                &.active,
                &:hover {
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
                    opacity: 1;
                    transform: translate(0, -5px);
                    border-bottom-color: #11aea7;

                    .tabTittle {
                        color: #374E62;
                    }
                }

            }

        }

    }

    .tab-content {
        background: #FCFEFF;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: rem(6px);
        padding: rem(25px);
        min-height: rem(230px);
        margin: rem(10px 5px 0);
        color: #0A0A0A;
    }

}

/*------------------------------------
  Clients Section
------------------------------------*/
.clienteleSection {
    padding: rem(75px 15px);

    &--detail {
        padding: rem(40px 15px 15px);
    }
}

.client-col {
    max-width: 150px;
    flex-grow: unset;
    flex-basis: auto;

    &>img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.clinet-viewMore {
    border: 1px solid #D3D3D3;
    width: 100%;
    height: 100%;
    font-size: 20px;
    color: #374E62;
    min-height: 136px;
}

/*------------------------------------
  Our Strategic Design Process
------------------------------------*/
.designProcess {
    background: #1F3646;
    border-radius: rem(6px);
    padding: rem(45px 15px);
    margin: rem(0 15px);

    @include media-breakpoint-up(xl) {
        margin: 0;
        padding: rem(60px 80px);
    }

    .nav-tabs {
        display: none;
        border-bottom: none;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: nowrap;

            .arrowBlock {
                width: calc((100% - 515px) / 5);
                height: rem(100px);

                .arrow {
                    display: block;
                    position: relative;
                    top: 50%;
                    height: rem(14px);
                    background-image: url(#{$folder-path}/arrow-point-to-right.png);
                    background-position: right;
                    background-repeat: no-repeat;
                    padding-right: rem(3px);

                    &:before {
                        content: "";
                        background: #C4D6E3;
                        height: rem(2px);
                        display: block;
                        position: relative;
                        top: rem(6px);
                    }
                }
            }
        }
    }

    .nav-link {
        color: #ffffff;
        padding: 0;
        min-height: rem(142px);
        transition: all ease-in-out .5s;

        .dpIcon {
            height: 100px;
            width: 100px;
            border-radius: 100%;
            border: 2px solid #FFFFFF;
            display: flex;
            margin: 0 auto;

            i {
                background-repeat: no-repeat;
                background-size: auto 45px;
                height: 100%;
                width: 100%;
                background-position: center;
            }
        }

        .dpTitlle {
            font-size: rem(18px);
            text-align: center;
            padding-top: 15px;

            @include media-breakpoint-up(md) {
                position: absolute;
                left: -25%;
                right: -25%;
                min-width: 150%;
                white-space: nowrap;
            }

            @include media-breakpoint-up(lg) {
                font-size: rem(20px);
            }
        }

        &:hover {
            color: #FF3137;
            border-color: transparent;
        }

        &.active {
            color: #FF3137;
            border-color: transparent;
            background: transparent;

            .dpIcon {
                background: #ffffff;
            }
        }
    }

    .tab-content {
        color: #BFBFBF;
        text-align: center;
        padding-top: rem(5px);

        @include media-breakpoint-up(md) {
            padding-top: rem(25px);
        }

        .mobTab {
            padding: rem(15px 0);

            &__header {
                padding-bottom: rem(15px);

                @include media-breakpoint-up(md) {
                    display: none;
                }

                a[aria-expanded="true"] .dpIcon {
                    background-color: #ffffff;
                }

            }

            .collapse {
                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .tab-content>.tab-pane {
            display: block !important;
            opacity: 1;
        }
    }

}

.strategyIcon {
    background-image: url(#{$folder-path}/strategy-icon.png);
}

.wireframesIcon {
    background-image: url(#{$folder-path}/wireframes-icon.png);
}

.developmentIcon {
    background-image: url(#{$folder-path}/we-share-your-passion.png);
}

.qaIcon {
    background-image: url(#{$folder-path}/app-qA-testing.png);
}

.launchIcon {
    background-image: url(#{$folder-path}/launch-icon.png);
}

.nav-link.active,
a[aria-expanded="true"] {
    .strategyIcon {
        background-image: url(#{$folder-path}/strategy-icon-black.png);
    }

    .wireframesIcon {
        background-image: url(#{$folder-path}/wireframes-icon-black.png);
    }

    .developmentIcon {
        background-image: url(#{$folder-path}/we-share-your-passion-black.png);
    }

    .qaIcon {
        background-image: url(#{$folder-path}/app-qA-testing-black.png);
    }

    .launchIcon {
        background-image: url(#{$folder-path}/launch-icon-black.png);
    }
}

/*------------------------------------
  Portfoli Section
------------------------------------*/
.portfolioSection {
    padding: rem(60px 15px);
}

/*------------------------------------
  Why Singsys
------------------------------------*/
.whySingsys {
    padding: rem(50px 15px);
    background: #F8FBFC;
    border-radius: rem(6px);

    .certification-col {
        max-width: 140px;

        @include media-breakpoint-up(md) {
            max-width: 200px;
        }

        .figure-img {
            background: $white;
            width: rem(120px);
            height: rem(120px);
            border-radius: 100%;
            margin: (0 auto 10px);
            box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

            @include media-breakpoint-up(md) {
                width: rem(150px);
                height: rem(150px);
            }

            img {
                max-width: 75%;
                max-height: 75%;

                @include media-breakpoint-up(md) {
                    max-width: 90%;
                    max-height: 90%;
                }
            }
        }

        .figure-caption {
            font-size: rem(12px);

            @include media-breakpoint-up(md) {
                font-size: rem(14px);
            }
        }

    }
}

/*------------------------------------
  Info Section
------------------------------------*/
.infoSection {
    padding: rem(70px 25px);

    .infoBlock {
        background: #ffffff;
        border-radius: rem(4px);
        min-width: 100%;

        @include media-breakpoint-up(sm) {
            min-width: 50%;
        }

        @include media-breakpoint-up(xl) {
            min-width: 0
        }

        .boxShadow {
            padding: rem(15px 15px 0);
            height: 100%;
        }

        &__head {
            h3 {
                font-size: rem(20px);
                color: #383838
            }
        }

        &__icon {
            max-width: 80px;
            text-align: right;
        }

        &__content {
            font-size: rem(14px);
            padding: rem(10px 0)
        }
    }
}