/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/roboto-v18-latin-regular.eot");
    /* IE9 Compat Modes */
    src: local("Roboto"), local("Roboto-Regular"), url("../fonts/roboto-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v18-latin-regular.woff") format("woff"), url("../fonts/roboto-v18-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v18-latin-regular.svg#Roboto") format("svg");
    /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/roboto-v18-latin-500.eot");
    /* IE9 Compat Modes */
    src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/roboto-v18-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-500.woff2") format("woff2"), url("../fonts/roboto-v18-latin-500.woff") format("woff"), url("../fonts/roboto-v18-latin-500.ttf") format("truetype"), url("../fonts/roboto-v18-latin-500.svg#Roboto") format("svg");
    /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/roboto-v18-latin-700.eot");
    /* IE9 Compat Modes */
    src: local("Roboto Bold"), local("Roboto-Bold"), url("../fonts/roboto-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-700.woff2") format("woff2"), url("../fonts/roboto-v18-latin-700.woff") format("woff"), url("../fonts/roboto-v18-latin-700.ttf") format("truetype"), url("../fonts/roboto-v18-latin-700.svg#Roboto") format("svg");
    /* Legacy iOS */
}

/* roboto-900 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/roboto-v18-latin-900.eot");
    /* IE9 Compat Modes */
    src: local("Roboto Black"), local("Roboto-Black"), url("../fonts/roboto-v18-latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v18-latin-900.woff2") format("woff2"), url("../fonts/roboto-v18-latin-900.woff") format("woff"), url("../fonts/roboto-v18-latin-900.ttf") format("truetype"), url("../fonts/roboto-v18-latin-900.svg#Roboto") format("svg");
    /* Legacy iOS */
}

body,
code,
h1,
h2,
h3,
h4,
h5,
h6,
kbd {
    font-family: 'Droid Serif', serif;
}

body {
    line-height: 1.8;
    font-size: 14px;

    @include media-breakpoint-up(md) {
        font-size: 16px;
    }
}

a:hover {
    text-decoration: none;
}

.hidden {
    display: none;
}

.visuallyhidden {
    opacity: 0;
}

.visible {
    opacity: 1;
}

.nobr {
    white-space: nowrap !important;
}

.wrap {
    white-space: normal !important;
}

.a-left {
    text-align: left !important;
}

.a-center {
    text-align: center !important;
}

.a-right {
    text-align: right !important;
}

.v-top {
    vertical-align: top;
}

.v-middle {
    vertical-align: middle;
}

.pos-relative {
    position: relative;
}

.pos-absolute {
    position: absolute;
}

.f-none {
    float: none !important;
}

.no-display {
    display: none !important;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-bg {
    background: none !important;
}

.no-bor {
    border: none !important
}

.no-boxshadow {
    box-shadow: none !important
}

.no_margin-top {
    margin-top: 0 !important;
}

.no_margin-bottom {
    margin-bottom: 0 !important;
}

.no_margin-right {
    margin-right: 0 !important;
}

.no_margin-left {
    margin-left: 0 !important;
}

.no_padding-top {
    padding-top: 0 !important;
}

.no_padding-bottom {
    padding-bottom: 0 !important;
}

.no_padding-right {
    padding-right: 0 !important;
}

.no_padding-left {
    padding-left: 0 !important;
}

.container {
    max-width: 100%
}

*::-moz-selection {
    background-color: rgba(55, 55, 55, 0.65);
    color: #ffffff;
}

*::-webkit-selection {
    background-color: rgba(55, 55, 55, 0.65);
    color: #ffffff;
}

*::-moz-selection {
    background-color: rgba(55, 55, 55, 0.65);
    color: #ffffff;
}

*::-webkit-selection {
    background-color: rgba(55, 55, 55, 0.65);
    color: #ffffff;
}

*::selection {
    background-color: rgba(55, 55, 55, 0.65);
    color: #ffffff;
}

* {
    -webkit-tap-highlight-color: transparent;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    z-index: 9999;
}

#preloader img {
    max-width: 280px;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.animated {
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    -o-animation-duration: 2s;
    -ms-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    animation-fill-mode: both;
}

u {
    text-decoration: none;
    display: inline-block;
    position: relative;
}

u::after {
    content: "";
    position: absolute;
    background-color: #0a0a0a;
    left: 0;
    right: 0;
    bottom: 5px;
    width: 100%;
    height: 1px;
}

html {
    overflow-x: hidden;
}

.main-page-container {
    position: relative;
    width: 100%;
    margin: 0;
    padding: rem(106px 0 0);
    background: #ffffff;

    @include media-breakpoint-up(sm) {
        padding: rem(80px 0 0);
    }

    @include media-breakpoint-up(xl) {
        padding: 105px 50px 0;
    }
}

.label-effect {
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #ccc;
    background-color: transparent;
}

.label-effect~.focus-border {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #4b81a9;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.label-effect:focus~.focus-border,
.has-content.label-effect~.focus-border {
    width: 100%;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.application-field-wrap label {
    color: #777777;
}

.label-effect~label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    top: 15px;
    color: #777777;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: -1;
}

.label-effect:focus~label,
.has-content.label-effect~label,
.dk-select-open-up~label,
.dk-select-open-down~label,
.is-invalid-input:not(:focus)~label,
.is-invalid-input:focus~label,
.form-field.is-invalid-input:focus~label {
    top: -3px;
    font-size: 12px;
    color: #225072;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.has-content.label-effect+label,
.has-content.label-effect+select+label {
    color: #777777;
}

:root .form-dropdown {
    position: relative;
    display: block;
    width: 100%;
}

:root .form-dropdown .application-field {
    display: block;
    cursor: pointer;
}

:root .form-dropdown::after {
    content: "";
    top: 0;
    right: 0px;
    width: 2.875rem;
    height: 2.875rem;
    margin: 0;
    padding: 0.5rem 0rem 0 0;
    position: absolute;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='40' height='18' viewBox='0 0 40 18'><polygon points='0,0 40,0 20,18' style='fill: rgb%2834, 34, 34%29'></polygon></svg>");
    border-color: transparent;
    background-size: 15px;
    background-position: 23px center;
    background-origin: content-box;
    background-repeat: no-repeat;
    pointer-events: none;
    -webkit-pointer-events: none;
}

/*------------------------------------
Links
------------------------------------*/
.link {
    display: inline-block;
}

.link__icon {
    position: relative;
    line-height: .7;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 1.375rem;
    height: 1.375rem;
    font-size: 1rem;
    color: #377dff;
    background-color: rgba(55, 125, 255, 0.1);
    border-radius: 50%;
    transition: 0.3s;
}

.link__icon-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.link:hover .link__icon {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

/* Collapse */
.link-collapse[aria-expanded="false"] .link-collapse__default {
    display: inline-block;
}

.link-collapse[aria-expanded="false"] .link-collapse__active {
    display: none;
}

.link-collapse[aria-expanded="true"] .link-collapse__default {
    display: none;
}

.link-collapse[aria-expanded="true"] .link-collapse__active {
    display: inline-block;
}

/* Link Light */
.link-light {
    color: rgba(255, 255, 255, 0.7);
    border-bottom: 1px dashed rgba(255, 255, 255, 0.7);
}

.link-light:hover {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.7);
}

/* Link Muted */
.link-muted {
    color: #8c98a4;
    border-bottom: 1px dashed #97a4af;
}

.link-muted:hover {
    border-color: #0052ea;
}

.link-muted__toggle-default {
    display: inline-block;
}

.link-muted__toggle-toggled {
    display: none;
}

.link-muted.toggled .link-muted__toggle-default {
    display: none;
}

.link-muted.toggled .link-muted__toggle-toggled {
    display: inline-block;
}

/* Link White */
.link-white {
    color: #fff;
    border-bottom: 1px dashed #fff;
}

.link-white:hover {
    color: #fff;
}

.boxShadow {
    box-shadow: 0px 4px 7px rgba(0, 0, 0, .16);
}

.section-title-large {
    color: #374E62;
    font-size: rem(20px);
    margin-bottom: rem(15px);

    @include media-breakpoint-up(md) {
        font-size: rem(28px);
    }
}

.pageHeading {
    margin-top: rem(70px);
    padding: rem(0 15px);

    @include media-breakpoint-up(lg) {
        padding: 0;
    }

}

.link {
    font-size: rem(15px);
    color: $darkest-grey;
    font-weight: $font-weight-bold;
    position: relative;
    display: inline-block;

    &::after {
        content: attr(data-hover);
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        overflow: hidden;
        color: $blue;
        transition: width .9s ease-in-out;
        white-space: nowrap;
    }

    &:hover {
        color: inherit;

        &::after {
            width: 100%;
        }
    }
}

.section-space {
    margin: rem(60px 0)
}

.section-vertical-space {
    padding: rem(0 15px) !important;
}


.small-container {
    max-width: 965px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

form {
    font-family: $sans-serif-font;
}

.services-accordion {
    padding: 24px 0 20px;

    @media only screen and (min-width: $sm) {
        padding: 60px 0 48px
    }
}

.services-head {
    max-width: 755px;
    margin: 0 auto;

    p {
        font-size: 16px;
        line-height: 23px;
        padding: 4px 0 30px;

        @media only screen and (min-width: $sm) {
            font-size: 22px;
            line-height: 32px;
            padding: 7px 0 64px;
        }
    }
}

.services-list .card {
    padding-bottom: 19px;
    border: none;

    @media only screen and (min-width: $sm) {
        padding-bottom: 16px;
    }

    .card-head {
        h2 {
            position: relative;
            padding-left: 34px;

            @media only screen and (min-width: $sm) {
                padding-left: 62px;
            }


            a {
                display: inline;
                font-family: 'Playfair Display', serif;
                color: #3c3c3c;
                font-weight: 400;
                font-size: 20px;
                line-height: 28px;

                &[aria-expanded="true"] {
                    color: $blue
                }

                @media only screen and (min-width: $sm) {
                    font-size: 28px;
                    line-height: 1.2;
                }

                &:before {
                    position: absolute;
                    content: '';
                    left: 0px;
                    top: 6px;
                    height: 18px;
                    width: 18px;
                    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='28' height='28' viewBox='0 0 28 28'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23120bad; fill-rule: evenodd; filter: url%28%23filter%29; %7D %3C/style%3E%3Cfilter id='filter' x='0' y='0' width='28' height='28' filterUnits='userSpaceOnUse'%3E%3CfeImage preserveAspectRatio='none' x='0' y='0' width='28' height='28' result='image' xlink:href='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCI+CiAgPGRlZnM+CiAgICA8c3R5bGU+CiAgICAgIC5jbHMtMSB7CiAgICAgICAgZmlsbDogdXJsKCNsaW5lYXItZ3JhZGllbnQpOwogICAgICB9CiAgICA8L3N0eWxlPgogICAgPGxpbmVhckdyYWRpZW50IGlkPSJsaW5lYXItZ3JhZGllbnQiIHgxPSIyOCIgeTE9IjI4IiB4Mj0iMCIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiM1YzFjOTUiLz4KICAgICAgPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjMDkwOWIwIi8+CiAgICA8L2xpbmVhckdyYWRpZW50PgogIDwvZGVmcz4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIvPgo8L3N2Zz4K'/%3E%3CfeComposite result='composite' operator='in' in2='SourceGraphic'/%3E%3CfeBlend result='blend' in2='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cpath id='Rectangle_6_copy_6' data-name='Rectangle 6 copy 6' class='cls-1' d='M28,16.25H16.25V28h-4.5V16.25H0v-4.5H11.75V0h4.5V11.75H28v4.5Z'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 18px auto;
                    transition: all .25s ease-in-out;

                    @media only screen and (min-width: $sm) {
                        height: 21px;
                        width: 21px;
                        left: 5px;
                        background-size: 21px auto;
                        top: 8px;
                    }
                }

                &[aria-expanded="true"]:before {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .card-body {
        padding-left: 38px;
        font-size: 16px;
        line-height: 23px;
        color: #3c3c3c;
        padding-top: 10px;

        h4 {
            margin-top: 20px;
            font-size: 16px;

            @media only screen and (min-width: $sm) {
                font-size: 22px;
            }
        }



        @media only screen and (min-width: $sm) {
            padding-left: 62px;
            font-size: 18px;
            line-height: 28px;
        }
    }
}

img {
    max-width: 100%;
}

h2 {
    color: #374E62;
    font-size: rem(20px);

    strong {
        font-weight: 500
    }

    @include media-breakpoint-up(md) {
        font-size: rem(28px);
    }
}

.customList ul {
    padding-left: 0;
    margin: 0;

    li {
        list-style: none;
        position: relative;
        padding-left: 60px;
        text-rendering: optimizeLegibility;
        margin: rem(7px 0);

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 12px;
            width: 47px;
            height: 2px;
            background: #747474;
        }

        li{
            padding-left: 30px;
            &::before{
                width: 20px;
            }
        }
    }
}


.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: rem(25px);
    height: 0;

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


button.close {
    position: absolute;
    z-index: 9;
    right: 0;
    top: 0;
    width: 45px;
    height: 45px;
    font-size: rem(50px);
    background: rgba(255,255,255,.5);
}

.modal-body{
    padding: 0;
}

.whiteArrow {
    .owl-nav {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin-top: rem(-25px);

        @include media-breakpoint-up(sm) {
            top: 50%;
        }

        button {
            position: absolute;
            border: 1px solid transparent;
            border-radius: 100%;
            transition: all ease-in-out .6s;
            width: rem(40px);
            height: rem(40px);
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
            z-index: 99;
            background-color: rgba(255,255,255,0.8)  !important;

            @include media-breakpoint-up(md) {
                width: rem(70px);
                height: rem(70px);
            }

            span {
                font-size: rem(60px);
                line-height: rem(30px);
                color: #e1e6e9;

                @include media-breakpoint-up(md) {
                    font-size: rem(80px);
                    line-height: rem(40px);
                }
            }

            &:hover {
                background-image: none;
                border-color: #e8e8e8;
                box-shadow: -1.24px -4.3px 61.14px 1.6px rgba(161, 161, 161, 0.45);

                span {
                    color: #232323;
                }
            }
        }

        .owl-prev{
            left: rem(10px);
        }

        .owl-next {
            right: rem(10px);
        }
    }

    .owl-dots {
        position: absolute;
        bottom: rem(5px);
        left: 0;
        right: 0;
        z-index: 99;
        text-align: center;

        button {
            border-radius: 100%;
            width: rem(10px);
            height: rem(10px);
            background: $black;
            margin: rem(5px);

            &.active {
                background: $white;
            }
        }
    }
}

.content-404{
    @include media-breakpoint-up(sm) {
        max-width: 50%
    }
}

.fullSummary div.collapse:not(.show) {
    height: 116px !important;
    overflow: hidden;
    display: block;
}

.fullSummary div.collapsing {
    min-height: 116px !important;
}

.fullSummary a.readMore.collapsed:after  {
    content: '+ Read More';
}

.fullSummary a.readMore:not(.collapsed):after {
    content: '- Read Less';
}

.fullSummary .readMoreBtn{
    @include media-breakpoint-up(md) {
        text-align: right;
    }
}

main.main-page-container a:hover {
    text-decoration: underline;
}

a.btn:hover, a.nav-link:hover, a.readmore, a.clinet-viewMore, a.readMore, .project-title a:hover, .services-list .card .card-head h2 a:hover, .engagementModelListing li>div .modelBoxAction a:hover{
	text-decoration: none !important;
}