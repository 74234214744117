.testimonials-section {
    padding: rem(15px 15px 0) !important;
    position: relative;

    .item {
        padding: rem(0 20px);

        @include media-breakpoint-up(lg) {
            padding: rem(0 70px);
        }

        background: #ffffff;

        .testimonial-block {
            position: relative;

            .testimonialImgeBlock {
                width: rem(239px);
                height: rem(255px);
                background-image: url(../images/testimonial-thumb-bg.png);
                background-repeat: no-repeat;
                background-size: 100% auto;
                margin: 0 auto;

                @include media-breakpoint-up(sm) {
                    margin: 0;
                    width: rem(284px);
                    height: rem(290px);
                }

                .testimonial-image {

                    width: rem(210px);
                    height: rem(210px);
                    border-radius: 100%;
                    margin-left: rem(14px);
                    @include media-breakpoint-up(sm) {
                        width: rem(250px);
                        height: rem(250px);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .testimonial-content {
            font-size: rem(14px);
            line-height: 1.8;
            padding: rem(30px 0px 0);
            min-height: rem(230px);

            @include media-breakpoint-up(sm) {
                position: absolute;
                right: 0;
                top: rem(20px);
                width: calc(100% - 290px);
            }

            @include media-breakpoint-up(md) {
                padding: rem(30px 20px 30px);
            }

            .testimonial-text {
                height: rem(100px);
            }

            h3 {
                font-family: 'Alex Brush', cursive;
                font-size: 24px;
                font-weight: 400;
                color: #000000;
                margin: 0;
                padding: 20px 0 0 0;
                text-transform: capitalize;
                line-height: normal;
                @include media-breakpoint-up(md) {
                     font-size: 40px;
                }
            }

            p {
                margin-bottom: 0 !important;
            }

            span,
            h3 span {
                font-family: 'Roboto', sans-serif;
                font-weight: 700;
                color: #343434;
                display: block;
                text-transform: uppercase;
            }
        }
    }

    .owl-carousel {
        .owl-nav {
            position: absolute;
            top: rem(145px);
            left: 0;
            right: 0;
            margin-top: rem(-25px);
            z-index: 9;

            @include media-breakpoint-up(sm) {
                top: 50%;
            }

            button {
                position: absolute;
                -webkit-appearance: none ;
                height: 120px;

                span {
                    font-size: rem(120px);
                    line-height: 0px;
                    color: #e1e6e9;
                }
            }

            .owl-next {
                right: 0;
            }
        }
        .owl-dots{
            display: none;
        }
    }
}