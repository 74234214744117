.storiesSection {
	.stories-scroll {
		width: 100%;
		overflow-x: auto;
		padding: rem(0 15px);

		.stories-item-list {

			.stories-item {
				max-width: 100%;
				flex: 0 0 auto;
				margin: rem(20px 0);

				@media only screen and (min-width: $smd) {
					max-width: 50%;
					flex: 0 0 50%;
				}

				@media only screen and (min-width: $lg) {
					max-width: 33%;
					flex: 0 0 33%;
				}

				.stories-thumb a {
					height: rem(230px);
					display: flex;
					justify-content: center;
					align-items: center;
					padding: rem(10px);

					@media only screen and (min-width: $lg) {
						height: rem(340px);
					}

					img {
						max-width: 100%;
						max-height: 100%;
					}
				}

				.project-title {
					margin-top: rem(15px);

					a {
						font-size: rem(20px);
						color: $darkest-grey;
						font-weight: 400;
					}
				}

				&:hover {
					a {
						color: $blue;
					}
				}
			}
		}
	}
}

.content-block {
	padding: rem(40px 15px);
}

.article-block {
	.col {
		max-width: 50%;
		padding: 0;
		@include media-breakpoint-down(md) {
			max-width: 100%;
			min-width: 100%;
		}
	}

	&__content {
		background: #f8f8f8;
		@include media-breakpoint-down(md) {
			order: 2;
		}
		
		&__inside{
			padding: rem(25px 30px);
		}
	}

	&__figure {
		justify-content: center;
		align-items: center;
		@include media-breakpoint-down(md) {
			text-align: center;
		}

		img {
			max-width: 100%;
		}
	}
}

.tech-col {
	max-width: rem(150px);
	flex-grow: unset;
	flex-basis: auto;
	margin: rem(8px 0);

	a{
		display: block;
	}

	.tech-col__icon {
		background: #f5f5f5;
		padding: rem(10px);
		width: 100%;
		height: 100%;
		min-height: rem(120px);
		justify-content: center;
		align-items: center;
		display: flex;
	}
}
