.job-section {
    padding-bottom: rem(80px);

    .job-paragraph {
        font-size: 22px;
        color: #3c3c3c;
        text-align: center;
        padding-top: 10px;
    }
}

.applying-position-dropown {
    padding: rem(5px 0 40px);

    h3 {
        font-size: 35px;
        color: #3c3c3c;
        font-weight: 400;
    }
}

.job-section {
    .address-wrapper {
        .address-heading {
            font-size: 14px !important;
            text-transform: uppercase;
            font-weight: 600;
            color: #0d0aaf;
        }
    }
}

.submit-form-section {
    p {
        font-size: 22px;
        max-width: 672px;
        margin: 0 auto;
    }

    .submit-btn {
        margin-top: 30px;
    }
}

.candidate-detail-form-wrapper {
    max-width: 661px;
}

.address-wrapper {
    padding: 20px 0;
}

ul.gender-checkboxes {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin-right: 20px;
    }
}

.local-address-checkbox {
    padding-top: 33px;
}


.select2 {
    display: block;
}

.select2-container--default {
    width: 100% !important;
    font-family: $sans-serif-font;

    .select2-dropdown {
        box-shadow: 0 0 6px rgba(0, 0, 0, .16), 0 6px 12px rgba(0, 0, 0, .32);
    }

    .select2-selection--single {
        border: none;
        border-radius: 0;
        font-size: 16px;
        background: $white no-repeat;
        background-image: linear-gradient(72deg, #693680 0%, #00A3B7 40%, #30C388 100%), linear-gradient(to bottom, silver, silver);
        background-size: 0 2px, 100% 1px;
        background-position: 50% 100%, 50% 100%;
        transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);

        .select2-selection__arrow {
            b {
                border-color: #000 transparent transparent transparent;
                border-width: 8px 7px 0 7px;
            }
        }

        &:focus {
            background-size: 100% 1px, 100% 1px;
            outline: none;
        }

        .select2-selection__rendered {
            color: #222222;

            &[title~="Choose"],
            &[title~="Define"],
            &[title~="Timeline"] {
                color: #b3b3b3
            }

            &[title~="Choose"]+.select2-selection__arrow b,
            &[title~="Define"]+.select2-selection__arrow b,
            &[title~="Timeline"]+.select2-selection__arrow b {
                border-color: #b3b3b3 transparent transparent transparent;
            }
        }
    }
}

.checkbox {
    position: relative;

    label {
        padding-left: 30px;
        font-size: 16px;
        color: #3c3c3c;
        position: relative;
    }

    input[type="checkbox"] {
        display: none;

        + {
            label {
                span {
                    width: 20px;
                    display: block;
                    height: 20px;
                    border: 1px solid;
                    position: absolute;
                    left: 0;
                    top: 4px;
                }
            }
        }

        &:checked {
            + {
                label {
                    span {
                        &:after {
                            content: '';
                            width: 14px;
                            height: 8px;
                            position: absolute;
                            left: 2px;
                            top: 3px;
                            border: 1px solid #000000;
                            border-right: 0;
                            border-top: 0;
                            transform: rotate(-41deg);
                        }
                    }
                }
            }
        }
    }
}


.radiobox {
    position: relative;

    label {
        padding-left: 30px;
        font-size: 16px;
        color: #3c3c3c;
        position: relative;
    }

    input[type="radio"] {
        display: none;

        + {
            label {
                span {
                    width: 20px;
                    height: 20px;
                    display: block;
                    position: absolute;
                    border-radius: 50%;
                    border: 2px solid #b3b3b3;
                    left: 0px;
                    top: 4px;
                }
            }
        }

        &:checked {
            + {
                label {
                    span {
                        &:after {
                            content: '';
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            background-color: #b3b3b3;
                            border-radius: 50%;
                            right: 3px;
                            top: 3px;
                        }
                    }
                }
            }
        }
    }
}

.apply-now-section {
    padding: 35px 0 20px;
    max-width: 755px;
    margin: 0 auto;
    font-size: 16px;

    @media only screen and (min-width: $sm) {
        font-size: 22px;
    }
}

.culture-section {
    margin-bottom: 40px;
}

.events-list ul {
    margin: 0;
    padding: 0;
    max-width: 100%;

    li {
        display: inline-block;
        list-style-type: none;
        border: 0;
        outline: none;
        float: left;
        padding-left: 5px;
        width: 33.33%;

        img {
            max-width: 100%
        }
    }
}

.join-team-section {
    padding: 10px 0 80px;

    .row {
        margin: 0 -7px;

        .col-md-3 {
            padding: 0 7px;
            width: 50%;

            @media only screen and (min-width: $xs) {
                width: 25%
            }

            .join-team {
                display: flex;
                background: $lightest-grey;
                align-content: center;
                justify-content: center;
                flex-wrap: wrap;
                padding: 10px;
                height: 100%;
                text-align: center;
            }

            img {
                width: 100%
            }
        }
    }
}

.loopVideo {
    object-fit: cover;
    width: 100%;
    vertical-align: bottom;
}

/* Work Benefits Section Style */
.workbenefits-section {
    background-image: linear-gradient(197deg, #06CB96, #00A7B3, #5E298E);
    padding: 60px 20px 80px;
    text-align: center;
}

.workbenefits-section .section-title-large {
    color: #ffffff;
    text-align: center;
}

.benefits-listing {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        width: 48%;
        margin: 50px 0 0;
        vertical-align: top;
        transition: all .5s;

        @include media-breakpoint-up(md) {
            width: 24%;
        }

        &:hover {
            transform: translateY(-10px);
        }
    }
}

.benifits-list-box {

    .benifits-list-image span {
        display: inline-block;
        width: 70px;
        margin: 0 0 20px;

        img {
            max-width: 100%;
        }
    }

    p {
        font-size: 16px;
        color: #ffffff;
        max-width: 130px;
        margin: 0 auto;
        line-height: 19px;
    }
}

/* Memorable Section Style */
.memorable-section {
    padding: 80px 0;

    .section-title-large {
        text-align: center;
        margin-bottom: 30px;
    }
}

.apply-section {
    background: #f8f8f8;
    padding: rem(25px 15px);
    text-align: center;
    margin-bottom: rem(80px);
}

.applying-position-dropown {
    padding: 25px 0;

    @include media-breakpoint-up(md) {
        padding: 35px 0;
    }

    h2 {
        font-size: 22px;
        color: #3c3c3c;
        font-weight: 400;
    }

    .position-dropdown {}

    .selectPosition,
    .positionDropdown {
        max-width: 320px;
    }
}

.officework-inside-content {
    font-family: Roboto, sans-serif;

    h2 {
        font-family: Roboto, sans-serif;
        margin-bottom: 30px;

        &.section-title-large {
            font-size: 15px;
            color: #999999;
            font-style: italic;
            font-weight: normal;
            letter-spacing: 1px;
            margin-bottom: 15px;
            font-family: Roboto, sans-serif;
        }
    }
}