.footer-section {
    position: relative;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;

    @include media-breakpoint-up(lg) {
        padding: rem(0 50px);
    }
}

.footer-info-section {
    position: relative;
    z-index: -1;
    font-size: rem(12px);
    line-height: 1.5;
    color: $white;
    padding: rem(50px 15px);

     @include media-breakpoint-up(lg) {
        padding: rem(60px 15px 160px)
     }

    li {
        position: relative;
        z-index: 1;
        font-family: 'Droid Serif', serif;

        strong {
            font-family: $sans-serif-font;
            color: #bebebe;
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        a{
            color: $white;
        }
    }
}

#sil-particles {
    position: fixed;
    z-index: 10;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.our-offices {
    margin: 0;
    list-style: none;
    padding: 0;
}

.office-address {
    font-family: $sans-serif-font;
    font-size: rem(14px);
    font-weight: 400;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 2.3rem;
    text-rendering: optimizeLegibility;
    position: relative;
    z-index: 1;

    h3 {
        font-family: $sans-serif-font;
        font-size: 14px;
        font-weight: 900;
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: rem(1px);
        margin-bottom: rem(15px);
    }
}

.social-icon {
    display: inline-block;
    margin: 0 10px 0 0;

    .fa-stack {
        height: rem(40px);
        width: rem(40px);
        line-height: 3rem;
        text-align: center;
    }

    .fa-circle {
        font-size: rem(48px);
        transition: all 0.2s cubic-bezier(0.4, 0, 1, 1) 0s;
    }

    .fa-circle+.fa {
        font-size: rem(22px);
        font-family: "Font Awesome 5 Brands";
        width: rem(48px);
    }
}

.icon-facebook {
    .fa-circle {
        color: #3b5998;
    }

    &:hover {
        .fa-circle {
            color: #1f4494;
        }
    }
}

.icon-twitter {
    .fa-circle {
        color: #55acee;
    }

    &:hover {
        .fa-circle {
            color: #329ef1;
        }
    }
}

.icon-linked-in {
    .fa-circle {
        color: #007ab9;
    }

    &:hover {
        .fa-circle {
            color: #015b8a;
        }
    }
}

.icon-instagram {
    .fa-circle {
        color: #E4405F;
    }

    &:hover {
        .fa-circle {
            color: #A51732;
        }
    }
}

.footer-copyright-section {
    padding: rem(15px);
    position: relative;
    z-index: -1;
    @include media-breakpoint-up(lg){
       padding: rem(15px 0); 
    }

    p {
        color: #616161;
        font-size: 12px;
        text-align: left;
        margin-bottom: 0;
    }
}

.copyright-mobile {
    display: none;
}

#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: #2a2a2a;
    background-image: url(https://s3-ap-southeast-1.amazonaws.com/singsys/img/diamond-bg-red.svg);
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: auto 90%;
    left: 0;
    right: 0;
    top: 0;
}